$mainColor: #FFFFFF;
$highlight: rgba(100,255,218, 0.8);
$neon: #E0E722;
$neonBgrd: rgba(224, 231, 34, 0.7);
$offwhite: #FAF9F6;
$cardBack: #F6F5F4;
$cardBack2: rgba(197, 194, 191, 0.4);
$moneyGreen: #118C4F;
$moneyGreenLite: rgba(17, 140, 79, 0.2);
// $black: #333333;
$black: #1B1B1B;
$black2: #0C0404;
$blackHeading: #282828;
$subHeading: #3E4044;
$black2: rgba(40,40,40, 0.88);
$cardBackground: #53585f;
$hr:  rgba(83,88,95, 0.2);
$dullBgrd: #f5f6f7;
$green: #138808;
$lighterGray: #c7c6c6;
$gray: #555555;
$gray2: #848482;
$pageBackground: #f0f2f5;
$loginBackground: #bdfeec;
// $loginBackground: #a8e0f3;

$lime: #74F0ED;
$lime2: #5AC3B0;
$limelight: rgba(116,240,237, 0.4);
$mainRed: #b51f29;
$chiliRed: #C21807;
$Sale: #960018;
$deepBlack: #000000;


$mainBlue: #0067B2;
$lightBlue: #1fb9e3;
$mainBlueLite2: rgba(0,74,173, 0.15);

// $extrasmall: 480px; //blue //phone
// $small: 576px; //yellow // mobile
// $medium: 768px; //orange //tablet
// $large: 960px;  //green  /// large

$extrasmall: 480px; //blue //phone
$small: 576px; //yellow // mobile
$medium: 720px; //orange //tablet
$large: 968px;  //green  /// large
$large2: 800px;  //green  /// large
$extralarge: 1100px;  //pink  /// extralarge


@mixin phone {
      @media (max-width: #{$extrasmall}) {
            @content
      }
};

@mixin mobile {
      @media (max-width: #{$small}) {
            @content
      }
};

@mixin tablet {
      @media (max-width: #{$medium}) {
            @content
      }
};

@mixin large {
      @media (max-width: #{$large}) {
            @content
      }
};
@mixin large2 {
      @media (max-width: #{$large2}) {
            @content
      }
};

@mixin extralarge {
      @media (max-width: #{$extralarge}) {
            @content
      }
};
