@import '../global.scss';


.logoSlideCarousel{
    // margin-top: 130px;
    // margin-bottom: 130px;
    gap: 5px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $cardBack;
    background-color: $mainColor;

    .logoSlideItems{
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0px 1px;
        align-items: center;
        justify-content: center;

        .item {
            flex: 1;
            // width: 10px;
            height: 40px;
            background-color: lightgrey;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        
    }
}