@import '../../global.scss';

.register {
    width: 100%;
    display: flex;
    margin-top: 70px;
    padding: 70px 100px 50px;
    align-items: center;
    justify-content: center;

    @include large {
        padding: 30px 0px 50px;
    }

    .registerLink {
        width: 100%;
        margin: 0 3px;
    }

    .registerWrapper {
        gap: 10px;
        width: 100%;
        display: flex;
        padding: 20px 30px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include mobile {
            padding: 20px 10px;
        }

        .top {
            gap: 20px;
            display: flex;

            span {
                color: gray;
                font-size: 25px;
                font-family: AcuminBold;
            }

            .topIcon {
                font-size: 28px;
                color: goldenrod;
            }
        }

        .registerDesc {
            display: flex;
            margin-top: -10px;

            p {
                color: gray;
                font-size: 14px;
                align-items: flex-start;
                letter-spacing: 0.4px;
                font-family: AcuminLight;
            }
        }

        .registerFormWrapper {
            gap: 6px;
            width: 450px;
            display: flex;
            padding: 20px 30px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            @include mobile {
                padding: 20px 20px;
            }
             @include phone{
                width: 400px;
                padding: 30px 20px;
            }


            .formItem {
                gap: 10px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include phone {
                    flex-direction: column;
                }

                input {
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                    padding: 0px 15px;
                    color: $black;
                    font-family: AcuminLight;
                    border: 0.7px solid lightgray;

                    @include phone {
                        padding: 18px 15px;
                    }
                }
                .phoneContainer {
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                    padding: 0px 15px 0px 0px;
                    color: $black;
                    font-family: AcuminLight;
                    border: 0.7px solid lightgray;

                    @include phone {
                        padding: 18px 15px;
                    }
                }

                input::placeholder {
                    font-size: 13px;
                    font-family: AcuminLight;

                    @include phone {
                        font-size: 15px;
                        color: gray;
                    }
                }

                input:focus {
                    outline: none;
                    background-color: white;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-text-fill-color: $black;
                    transition: background-color 5000s ease-in-out 0s;
                }

            }

            .phoneValid{
                color: crimson;
                font-size: 10px;
            }
            .phoneInput{
                gap: 10px;
                display: flex;
            }
            .passValid{
                color: crimson;
                font-size: 10px;
                margin-bottom: 10px;
            }

            .password {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                position: relative;
                padding: 0px 3px;
                justify-content: space-between;
                border: 0.7px solid lightgray;
                background-color: $mainColor;

                .passwordInput {
                    flex: 2.5;
                    height: 100%;
                    border: none;
                    font-size: 15px;
                    padding: 0px 15px;
                }

                .passwordInput::placeholder {
                    font-size: 14px;
                    font-family: AcuminLight;
                    @include mobile{
                        font-size: 16px;
                    }
                }

                .passwordInput:focus {
                    outline: none;
                }

                label {
                    flex: 0.3;
                    height: 35px;
                    color: gray;
                    display: flex;
                    text-align: center;
                    padding: 0px 20px;
                    cursor: pointer;
                    align-items: center;
                    background-color: $hr;
                }

            }

            .registerBtn {
                width: 100%;
                height: 40px;
                font-size: 14px;
                margin-top: 0px;
                padding: 0px 15px;
                cursor: pointer;
                color: $mainColor;
                letter-spacing: 0.6px;
                border: 1px solid $black;
                background-color: $black;

            }

            .registerFooter {
                gap: 10px;
                width: 100%;
                display: flex;
                margin-top: 5px;
                align-items: center;
                flex-direction: column;

                span {
                    font-size: 10px;
                    font-family: AcuminLight;
                }
                p {
                    font-size: 12px;
                    font-family: AcuminLight;
                }
                .registerLoginLink{
                    font-size: 12px;
                    color: $mainBlue;
                    text-decoration: none;
                    font-family: AcuminBold;
                }
                .registerLink{
                    font-size: 10px;
                    color: $mainBlue;
                    text-decoration: none;
                    font-family: AcuminBold;
                }
            }

        }
    }
}