.currentChart{
      flex: 3;
      padding: 10px;
      border-radius: 5px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: gray;

            .title{
                  font-size: 16px;
                  font-weight: 500;
            }
      }

      .bottom{
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .chart{
                  width: 100px;
                  height: 100px;
            }

            .title{
                  font-weight: 500;
                  color: gray;
                  letter-spacing: 0.5px;
                  font-family: AcuminLight;
            }

            .amount{
                  font-size: 30px;
            }

            .desc{
                  font-weight: 300;
                  font-size: 14px;
                  color: gray;
                  text-align: center;
                  letter-spacing: 0.5px;
                  font-family: AcuminLight;
            }

            .summary{
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 22px;

                  .item{
                        text-align: center;
                  }

                  .itemTitle{
                        font-size: 14px;
                        color: gray;
                  }

                  .itemResult{
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        font-size: 14px;

                        &.positive{
                              color: green;
                        }
                        &.negative{
                              color: red;
                        }
                  }
            }
      }
}