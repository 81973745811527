@import url('https://fonts.googleapis.com/css2?family=Aoboshi+One&family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

@import './global.scss';

//coming soon font
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Yellowtail&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Kaushan+Script&family=Yellowtail&display=swap');


//ACUMIN FONT
@font-face {
    font-family: AcuminPro;
    font-style: 'normal';
    src: url(./fonts/AcuminPro.otf);
}
@font-face {
    font-family: AcuminBold;
    font-style: 'normal';
    src: url(./fonts/AcuminBold.otf);
}
@font-face {
    font-family: AcuminLight;
    font-style: 'normal';
    src: url(./fonts/AcuminLight.otf);
}
@font-face {
    font-family: AcuminExtraLight;
    font-style: 'normal';
    src: url(./fonts/AcuminExtraLight.otf);
}
@font-face {
    font-family: AcuminMedium;
    font-style: 'normal';
    src: url(./fonts/AcuminMedium.otf);
}
@font-face {
    font-family: AcuminThin;
    font-style: 'normal';
    src: url(./fonts/AcuminThin.otf);
}
@font-face {
    font-family: AcuminLightItalic;
    font-style: 'normal';
    src: url(./fonts/AcuminLightItalic.otf);
}

// logo font
@font-face {
    font-family: LogoFont;
    font-style: 'normal';
    src: url(./fonts/logoFont.otf);
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: AcuminMedium;
}


.app {

    .link {
        display: flex;
        color: inherit;
        align-items: center;
        text-decoration: none;
        justify-content: center;
    }

    .toast-message {
        background: darkblue;
        color: #fff;
        font-size: 20px;
        width: 34vw;
        padding: 30px 20px;
    }

    .container {
        width: 1200px;

        @include large {
            width: 960px;
        }

        @include tablet {
            width: 720px;
        }

        @include mobile {
            width: 576px;
        }


        a {
            color: inherit;
            text-decoration: none;
        }
        



    }
}

.customerAccount {

    .link {
        display: flex;
        color: inherit;
        align-items: center;
        text-decoration: none;
        justify-content: center;
    }
    .accountHr{
        top: 82px;
        height: 10px;
        display: flex;
        width: 100%;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: $mainColor;
        border-bottom: 0.4px solid lightgray;
    }

    .accWrapper {
        gap: 10px;
        display: flex;
        padding: 0px 60px;
        margin-top: 95px;

        @include extralarge{
            padding: 0px 20px;
        }
        @include tablet{
            flex-direction: column;
        }
    
        .accountDetailsWrapper {
            flex: 5;
            display: flex;
            // border: 0.4px solid lightgray;
            background-color: $offwhite;
        }
    }

   
}

.adminAccount{

    .link {
        display: flex;
        color: inherit;
        align-items: center;
        text-decoration: none;
        justify-content: center;
    }
    .adminHr{
        top: 60px;
        height: 5px;
        display: flex;
        width: 100%;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: $mainColor;
        border-bottom: 0.4px solid lightgray;
    }

    .adminWrapper {
        gap: 10px;
        display: flex;
        padding: 0px 30px;
        margin-top: 69px;

        width: 100%;
        height: 100%;
    
        .adminDetailsWrapper {
            flex: 6;
            display: flex;
            overflow-y: auto;
            border: 0.4px solid lightgray;
            background-color: $offwhite;
        }
    }
}