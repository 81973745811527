@import "../../global.scss";

// ----------- NAVBAR -----------
.navbar {
    top: 40px;
    height: 60px;
    display: flex;
    z-index: 3;
    z-index: 4;
    width: 100%;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: $mainColor;

    // background-color: pink;

    @include large{
        display: none;
    }

    .navbarLink{
        color: inherit;
        text-decoration: none;
    }

    .navbarWrapper {
        width: 100%;
        display: flex;
        height: 100%;
        padding: 0px 30px;
        align-items: center;
        justify-content: space-between;

        .left {
            // flex: 0.8;
            gap: 20px;
            height: 100%;
            display: flex;
            color: $black;
            align-items: center;
            justify-content: flex-start;

            .navLogo {
                color: $black;
                font-size: 30px;
                letter-spacing: -0.8px;
                font-family: 'DM Serif Display', serif;
                // font-family: 'Crimson Text', serif;
            }
        }

        .navCenter {
            gap: 15px;
            height: 100%;
            display: flex;
            list-style: none;
            align-items: center;

            .dash{
                color: $mainBlue;
                font-family: AcuminLight;
            }
        }

        .right {
            gap: 10px;
            height: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-end;

            .icons {
                gap: 20px;
                height: 100%;
                display: flex;
                color: $mainColor;
                color: $deepBlack;
                margin-right: 20px;
                align-items: center;
                justify-content: space-between;

                .activeUserWrapper {
                    gap: 5px;
                    display: flex;
                    height: 100%;
                    cursor: pointer;
                    align-items: center;

                    .icon {
                        font-size: 20px;
                    }

                    .secIcon {
                        margin-top: 3px;
                        color: $lime;

                        color: $deepBlack;
                    }

                    span {
                        font-size: 14px;
                        margin-top: 5px;
                        letter-spacing: 0.4px;
                        font-family: AcuminLight;
                    }

                    h3 {
                        font-weight: 300;
                        font-size: 14px;
                        margin-top: 5px;
                        color: $mainBlue;
                    }
                }

                .iconWrapper {
                    gap: 10px;
                    display: flex;
                    height: 100%;
                    cursor: pointer;
                    align-items: center;

                    .likeIcon {
                        display: flex;
                        align-items: center;
                        position: relative;

                        .icon {
                            font-size: 23px;
                            cursor: pointer;
                            font-family: AcuminLight;
                        }

                        span {
                            font-size: 11px;
                            margin-top: 2px;
                            margin-left: 8px;
                            color: $lime;

                            color: $deepBlack;
                            position: absolute;
                        }
                    }

                    .cartIcon {
                        display: flex;

                        .icon {
                            font-size: 23px;
                            cursor: pointer;
                            font-family: AcuminLight;
                        }

                        .cartAmtWrapper {
                            width: 18px;
                            height: 17px;
                            display: flex;
                            font-size: 11px;
                            margin-Left: 15px;
                            margin-Top: -3px;
                            color: $mainColor;
                            border-radius: 50%;
                            align-items: center;
                            position: absolute;
                            justify-content: center;
                            background-color: $mainColor;

                            background-color: $deepBlack;

                            span {
                                font-size: 11px;
                                margin-Top: 3px;
                                color: $deepBlack;

                                color: $mainColor;
                            }
                        }
                    }
                }

                hr {
                    width: 100%;
                    display: flex;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    border: 0.4px solid lightgray;
                }

                .options {
                    top: 60px;
                    gap: 5px;
                    z-index: 1;
                    z-index: 4;
                    width: 180px;
                    color: $black;
                    display: flex;
                    list-style: none;
                    padding: 10px 5px;
                    position: absolute;
                    border-radius: 3px;
                    flex-direction: column;
                    background-color: $mainColor;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                    .optionsLink {
                        color: inherit;
                        text-decoration: none;
                    }

                    .menuItem {
                        cursor: pointer;
                        padding: 10px 10px;

                        &:hover {
                            color: $mainBlue;
                            background-color: lightgrey;
                        }

                        &:focus {
                            background-color: lightgrey;

                            @include tablet {
                                border: none;
                            }
                        }

                        span {
                            font-size: 14px;
                            font-family: AcuminLight;
                        }

                        h3 {
                            color: $subHeading;
                            font-size: 14px;
                            font-family: AcuminBold;
                        }
                    }

                    .menuItem2 {
                        gap: 10px;
                        display: flex;
                        padding: 10px 10px;
                        // justify-content: space-between;

                        .optionBtn1 {
                            width: 100%;
                            font-size: 13px;
                            cursor: pointer;
                            padding: 10px 10px;
                            color: $subHeading;
                            border-radius: 5px;
                            font-family: AcuminLight;
                            border: 0.4px solid $black;
                            background-color: $mainColor;

                            &:hover {
                                border: 0.4px solid $gray;
                            }
                        }

                        .optionBtn2 {
                            width: 100%;
                            border: none;
                            font-size: 13px;
                            cursor: pointer;
                            padding: 10px 10px;
                            color: $mainColor;
                            border-radius: 5px;
                            font-family: AcuminLight;
                            background-color: $black;

                            &:hover {
                                background-color: $subHeading;
                            }
                        }

                    }

                    .menuItem3 {
                        cursor: pointer;
                        padding: 10px 10px;
                        color: $subHeading;

                        &:hover {
                            background-color: lightgrey;

                            h3 {
                                color: crimson;
                            }
                        }

                        &:focus {
                            background-color: lightgrey;

                            @include tablet {
                                border: none;
                            }
                        }

                        h3 {
                            font-size: 14px;
                            font-family: AcuminBold;
                        }
                    }

                }

            }
        }

        .item {
            display: flex;
            font-size: 15px;
            align-items: center;
        }
    }
}



/////ADMIN NAV/////////

.promoNav {
    top: 0;
    height: 60px;
    display: flex;
    z-index: 3;
    width: 100%;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: $mainColor;

    .navbarLink{
        color: inherit;
        text-decoration: none;
    }

    // background-color: pink;

    .navbarWrapper {
        width: 100%;
        display: flex;
        height: 100%;
        padding: 0px 30px;
        justify-content: space-between;

        .left {
            // flex: 0.8;
            gap: 20px;
            height: 100%;
            display: flex;
            color: $black;
            align-items: center;
            justify-content: flex-start;

            .navLogo {
                color: $black;
                font-size: 30px;
                letter-spacing: -0.8px;
                font-family: 'DM Serif Display', serif;
                // font-family: 'Crimson Text', serif;
            }
        }

        .navCenter {
            gap: 15px;
            height: 100%;
            display: flex;
            list-style: none;
            align-items: center;

            .dash{
                font-size: 16px;
                color: $mainBlue;
                margin-top: -5px;
                font-family: AcuminLight;
            }
        }

        .right {
            gap: 10px;
            height: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-end;

            .icons {
                gap: 20px;
                height: 100%;
                display: flex;
                color: $mainColor;
                color: $deepBlack;
                margin-right: 20px;
                align-items: center;
                justify-content: space-between;

                .activeUserWrapper {
                    gap: 5px;
                    display: flex;
                    height: 100%;
                    cursor: pointer;
                    align-items: center;

                    .icon {
                        font-size: 20px;
                    }

                    .secIcon {
                        margin-top: 3px;
                        color: $lime;

                        color: $deepBlack;
                    }

                    span {
                        font-size: 14px;
                        margin-top: 5px;
                        letter-spacing: 0.4px;
                        font-family: AcuminLight;
                    }

                    h3 {
                        font-weight: 300;
                        font-size: 14px;
                        margin-top: 5px;
                        color: $mainBlue;
                    }
                }

                .iconWrapper {
                    gap: 10px;
                    display: flex;
                    height: 100%;
                    cursor: pointer;
                    align-items: center;

                    .likeIcon {
                        display: flex;
                        align-items: center;
                        position: relative;

                        .icon {
                            font-size: 23px;
                            cursor: pointer;
                            font-family: AcuminLight;
                        }

                        span {
                            font-size: 11px;
                            margin-top: 2px;
                            margin-left: 8px;
                            color: $lime;

                            color: $deepBlack;
                            position: absolute;
                        }
                    }

                    .cartIcon {
                        display: flex;

                        .icon {
                            font-size: 23px;
                            cursor: pointer;
                            font-family: AcuminLight;
                        }

                        .cartAmtWrapper {
                            width: 18px;
                            height: 17px;
                            display: flex;
                            font-size: 11px;
                            margin-Left: 15px;
                            margin-Top: -3px;
                            color: $mainColor;
                            border-radius: 50%;
                            align-items: center;
                            position: absolute;
                            justify-content: center;
                            background-color: $mainColor;

                            background-color: $deepBlack;

                            span {
                                font-size: 11px;
                                margin-Top: 3px;
                                color: $deepBlack;

                                color: $mainColor;
                            }
                        }
                    }
                }

                hr {
                    width: 100%;
                    display: flex;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    border: 0.4px solid lightgray;
                }

                .options {
                    top: 60px;
                    gap: 5px;
                    z-index: 1;
                    z-index: 4;
                    width: 180px;
                    color: $black;
                    display: flex;
                    list-style: none;
                    padding: 10px 5px;
                    position: absolute;
                    border-radius: 3px;
                    flex-direction: column;
                    background-color: $mainColor;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                    // margin-left: -100px;

                    .optionsLink {
                        color: inherit;
                        text-decoration: none;
                    }

                    .menuItem {
                        cursor: pointer;
                        padding: 10px 10px;

                        &:hover {
                            color: $mainBlue;
                            background-color: lightgrey;
                        }

                        &:focus {
                            background-color: lightgrey;

                            @include tablet {
                                border: none;
                            }
                        }

                        span {
                            font-size: 14px;
                            font-family: AcuminLight;
                        }

                        h3 {
                            color: $subHeading;
                            font-size: 14px;
                            font-family: AcuminBold;
                        }
                    }

                    .menuItem2 {
                        gap: 10px;
                        display: flex;
                        padding: 10px 10px;
                        // justify-content: space-between;

                        .optionBtn1 {
                            width: 100%;
                            font-size: 13px;
                            cursor: pointer;
                            padding: 10px 10px;
                            color: $subHeading;
                            border-radius: 5px;
                            font-family: AcuminLight;
                            border: 0.4px solid $black;
                            background-color: $mainColor;

                            &:hover {
                                border: 0.4px solid $gray;
                            }
                        }

                        .optionBtn2 {
                            width: 100%;
                            border: none;
                            font-size: 13px;
                            cursor: pointer;
                            padding: 10px 10px;
                            color: $mainColor;
                            border-radius: 5px;
                            font-family: AcuminLight;
                            background-color: $black;

                            &:hover {
                                background-color: $subHeading;
                            }
                        }

                    }

                    .menuItem3 {
                        cursor: pointer;
                        padding: 10px 10px;
                        color: $subHeading;

                        &:hover {
                            background-color: lightgrey;

                            h3 {
                                color: crimson;
                            }
                        }

                        &:focus {
                            background-color: lightgrey;

                            @include tablet {
                                border: none;
                            }
                        }

                        h3 {
                            font-size: 14px;
                            font-family: AcuminBold;
                        }
                    }

                }

            }
        }

        .item {
            display: flex;
            font-size: 15px;
            align-items: center;
        }
        
    }

}


// .navbar {
    // top: 40px;
    // height: 60px;
//     display: flex;
//     z-index: 3;
//     width: 100%;
//     position: fixed;
//     align-items: center;
//     justify-content: center;
//     background-color: $mainColor;

//     // background-color: pink;

//     .navbarWrapper {
//         width: 100%;
//         display: flex;
//         height: 100%;
//         padding: 0px 30px;
//         justify-content: space-between;

//         .left {
//             flex: 1;
//             gap: 32px;
//             width: 100%;
//             // height: 100%;
//             display: flex;
//             list-style: none;
//             align-items: center;

//             .dash {
//                 width: 100%;
//                 color: $black;
//                 padding: 7px;
//                 height: 100%;
//                 font-size: 15px;
//                 font-weight: 300;
//                 cursor: pointer;
//                 font-family: AcuminExtraLight;
//                 border-bottom: 2px solid transparent;

//                 &:hover {
//                     font-family: AcuminMedium;
//                     border-bottom: 1px solid $black;
//                 }

//                 &.active {
//                     font-family: AcuminMedium;
//                     border-bottom: 1px solid $black;
//                 }
//             }
//         }

//         .navCenter {
//             flex: 1;
//             gap: 20px;
//             width: 100%;
//             height: 100%;
//             display: flex;
//             color: $black;
//             align-items: center;
//             justify-content: center;

//             .mainLogo {
//                 display: flex;

//                 .navLogo {
//                     font-size: 36px;
//                     font-weight: 700;
//                     letter-spacing: -0.5px;
//                     font-family: 'DM Serif Display', serif;
//                 }
//             }

//         }

//         .right {
//             flex: 1;
//             gap: 25px;
//             height: 100%;
//             display: flex;
//             position: relative;
//             align-items: center;
//             justify-content: flex-end;

//             .icons {
//                 gap: 30px;
//                 height: 100%;
//                 display: flex;
//                 color: $black;
//                 margin-right: 20px;
//                 align-items: center;
//                 justify-content: space-between;

//                 .activeUserWrapper {
//                     gap: 5px;
//                     display: flex;
//                     height: 100%;
//                     cursor: pointer;
//                     align-items: center;

//                     .icon {
//                         font-size: 26px;
//                     }

//                     .secIcon {
//                         margin-top: 3px;
//                         color: $mainBlue;
//                     }

//                     span {
//                         font-weight: 300;
//                         font-size: 14px;
//                         margin-top: 5px;
//                     }

//                     h3 {
//                         font-weight: 300;
//                         font-size: 14px;
//                         margin-top: 5px;
//                         color: $mainBlue;
//                     }
//                 }

//                 .adminWrapper {
//                     gap: 4px;
//                     display: flex;
//                     margin-top: 5px;
//                     align-items: center;
//                     justify-content: center;

//                     .adminTitle {
//                         font-size: 13px;
//                         color: $mainBlue;
//                         letter-spacing: 0.4px;
//                         font-family: AcuminLight;
//                     }

//                     .adminName {
//                         font-size: 15px;
//                         color: $mainBlue;
//                         letter-spacing: 0.4px;
//                         font-family: AcuminBold;
//                     }
//                 }

//                 .link {
//                     color: inherit;
//                     text-decoration: none;
//                 }

//                 .iconWrapper {
//                     gap: 15px;
//                     display: flex;
//                     height: 100%;
//                     cursor: pointer;
//                     align-items: center;

//                     .likeIcon {
//                         display: flex;
//                         align-items: center;
//                         position: relative;

//                         .icon {
//                             font-size: 25px;
//                             cursor: pointer;
//                         }

//                         span {
//                             font-size: 11px;
//                             margin-top: 2px;
//                             margin-left: 9px;
//                             color: $mainBlue;
//                             position: absolute;
//                         }
//                     }

//                     .cartIcon {
//                         display: flex;

//                         .icon {
//                             font-size: 25px;
//                             cursor: pointer;
//                         }

//                         .cartAmtWrapper {
//                             width: 18px;
//                             height: 17px;
//                             display: flex;
//                             font-size: 11px;
//                             margin-Left: 15px;
//                             margin-Top: -3px;
//                             color: $mainColor;
//                             border-radius: 50%;
//                             align-items: center;
//                             position: absolute;
//                             justify-content: center;
//                             background-color: $black;

//                             span {
//                                 font-size: 11px;
//                                 margin-Top: 3px;
//                                 color: $mainColor;
//                             }
//                         }
//                     }
//                 }

//                 hr {
//                     width: 100%;
//                     display: flex;
//                     margin-top: 5px;
//                     margin-bottom: 5px;
//                     border: 0.4px solid lightgray;
//                 }

//                 .options {
//                     top: 60px;
//                     gap: 5px;
//                     z-index: 1;
//                     width: 180px;
//                     color: $black;
//                     display: flex;
//                     list-style: none;
//                     padding: 10px 5px;
//                     position: absolute;
//                     border-radius: 3px;
//                     flex-direction: column;
//                     background-color: $mainColor;
//                     box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

//                     .optionsLink {
//                         color: inherit;
//                         text-decoration: none;
//                     }

//                     .menuItem {
//                         cursor: pointer;
//                         padding: 10px 10px;

//                         &:hover {
//                             color: $mainBlue;
//                             background-color: lightgrey;
//                         }

//                         &:focus {
//                             background-color: lightgrey;

//                             @include tablet {
//                                 border: none;
//                             }
//                         }

//                         span {
//                             font-size: 14px;
//                             font-family: AcuminLight;
//                         }

//                         h3 {
//                             color: $subHeading;
//                             font-size: 14px;
//                             font-family: AcuminBold;
//                         }
//                     }

//                     .menuItem2 {
//                         gap: 10px;
//                         display: flex;
//                         padding: 10px 10px;
//                         // justify-content: space-between;

//                         .optionBtn1 {
//                             width: 100%;
//                             font-size: 13px;
//                             cursor: pointer;
//                             padding: 10px 10px;
//                             color: $subHeading;
//                             border-radius: 5px;
//                             font-family: AcuminLight;
//                             border: 0.4px solid $black;
//                             background-color: $mainColor;

//                             &:hover {
//                                 border: 0.4px solid $gray;
//                             }
//                         }

//                         .optionBtn2 {
//                             width: 100%;
//                             border: none;
//                             font-size: 13px;
//                             cursor: pointer;
//                             padding: 10px 10px;
//                             color: $mainColor;
//                             border-radius: 5px;
//                             font-family: AcuminLight;
//                             background-color: $black;

//                             &:hover {
//                                 background-color: $subHeading;
//                             }
//                         }

//                     }

//                     .menuItem3 {
//                         cursor: pointer;
//                         padding: 10px 10px;
//                         color: $subHeading;

//                         &:hover {
//                             background-color: lightgrey;

//                             h3 {
//                                 color: crimson;
//                             }
//                         }

//                         &:focus {
//                             background-color: lightgrey;

//                             @include tablet {
//                                 border: none;
//                             }
//                         }

//                         h3 {
//                             font-size: 14px;
//                             font-family: AcuminBold;
//                         }
//                     }

//                 }

//             }
//         }

//         .item {
//             display: flex;
//             font-size: 15px;
//             align-items: center;
//         }
//     }
// }

// ----------- PROMO NAV -----------

.promoNav {
    top: 0;
    height: 60px;
    display: flex;
    z-index: 3;
    width: 100%;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: $mainColor;

    // background-color: pink;

    .navbarWrapper {
        width: 100%;
        display: flex;
        height: 100%;
        padding: 0px 30px;
        justify-content: space-between;

        .left {
            flex: 1;
            gap: 32px;
            display: flex;
            list-style: none;
            align-items: center;
        }

        .navCenter {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            color: $black;
            font-size: 42px;
            // font-size: 38px;
            align-items: center;
            justify-content: center;

            span {
                margin-top: 8px;
                letter-spacing: -0.5px;
                font-family: 'DM Serif Display', serif;
            }
        }

        .right {
            flex: 1;
            gap: 25px;
            height: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-end;

            .icons {
                gap: 30px;
                height: 100%;
                display: flex;
                color: $black;
                margin-right: 20px;
                align-items: center;
                justify-content: space-between;

                .iconWrapper {
                    gap: 5px;
                    display: flex;
                    height: 100%;
                    cursor: pointer;
                    align-items: center;

                    .icon {
                        font-size: 26px;
                    }

                    .secIcon {
                        margin-top: 3px;
                        color: $mainBlue;
                    }

                    span {
                        font-weight: 300;
                        font-size: 14px;
                        margin-top: 5px;
                    }

                    h3 {
                        font-weight: 300;
                        font-size: 14px;
                        margin-top: 5px;
                        color: $mainBlue;
                    }
                }

                .adminWrapper {
                    gap: 4px;
                    display: flex;
                    margin-top: 5px;
                    align-items: center;
                    justify-content: center;

                    .adminTitle {
                        font-size: 13px;
                        color: $mainBlue;
                        letter-spacing: 0.4px;
                        font-family: AcuminLight;
                    }

                    .adminName {
                        font-size: 15px;
                        color: $mainBlue;
                        letter-spacing: 0.4px;
                        font-family: AcuminBold;
                    }
                }

                .link {
                    color: inherit;
                    text-decoration: none;
                }

                .cartIcon {
                    display: flex;

                    .icon {
                        font-size: 26px;
                        cursor: pointer;
                    }

                    .cartAmtWrapper {
                        width: 18px;
                        height: 17px;
                        display: flex;
                        font-size: 11px;
                        margin-Left: 15px;
                        margin-Top: -3px;
                        color: $mainColor;
                        border-radius: 50%;
                        align-items: center;
                        position: absolute;
                        justify-content: center;
                        background-color: $black;

                        span {
                            font-size: 11px;
                            margin-Top: 3px;
                            color: $mainColor;
                        }
                    }
                }

                hr {
                    width: 100%;
                    display: flex;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    border: 0.4px solid lightgray;
                }

                .options {
                    top: 60px;
                    gap: 5px;
                    z-index: 1;
                    width: 180px;
                    color: $black;
                    display: flex;
                    list-style: none;
                    padding: 10px 5px;
                    position: absolute;
                    border-radius: 3px;
                    flex-direction: column;
                    background-color: $mainColor;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                    .optionsLink {
                        color: inherit;
                        text-decoration: none;
                    }

                    .menuItem {
                        cursor: pointer;
                        padding: 10px 10px;

                        &:hover {
                            color: $mainBlue;
                            background-color: lightgrey;
                        }

                        &:focus {
                            background-color: lightgrey;

                            @include tablet {
                                border: none;
                            }
                        }

                        span {
                            font-size: 14px;
                            font-family: AcuminLight;
                        }

                        h3 {
                            color: $subHeading;
                            font-size: 14px;
                            font-family: AcuminBold;
                        }
                    }

                    .menuItem2 {
                        gap: 10px;
                        display: flex;
                        padding: 10px 10px;
                        // justify-content: space-between;

                        .optionBtn1 {
                            width: 100%;
                            font-size: 13px;
                            cursor: pointer;
                            padding: 10px 10px;
                            color: $subHeading;
                            border-radius: 5px;
                            font-family: AcuminLight;
                            border: 0.4px solid $black;
                            background-color: $mainColor;

                            &:hover {
                                border: 0.4px solid $gray;
                            }
                        }

                        .optionBtn2 {
                            width: 100%;
                            border: none;
                            font-size: 13px;
                            cursor: pointer;
                            padding: 10px 10px;
                            color: $mainColor;
                            border-radius: 5px;
                            font-family: AcuminLight;
                            background-color: $black;

                            &:hover {
                                background-color: $subHeading;
                            }
                        }



                    }

                }

            }
        }

        .item {
            display: flex;
            font-size: 15px;
            align-items: center;
        }
    }

}





