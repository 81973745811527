@import "../../global.scss";

//FEATURED BRANDS
.brandCard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 0.4px solid lightgray;

    .image {
        width: 100%;
        height: 100%;
        // height: 380px;
        // height: 350px;
        overflow: hidden;
        position: relative;

        .mainImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    .logo {
        width: 100%;
        height: 70px;
        height: 50px;
        overflow: hidden;
        background-color: $offwhite;

        .logoImg {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background-color: #F6F5F4;
        }

    }


}


//FEATURED PRODUCTS
.productCard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $cardBack;
    border: 0.4px solid lightgray;

    .trendingImage {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        .trendingImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

    .titles {
        gap: 5px;
        // margin-top: 5px;
        display: flex;
        padding-left: 10px;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        @include phone{
            width: 200px;
            width: 180px;
        }

        h3 {
            font-size: 10.5px;
            text-align: center;
            letter-spacing: 0.5px;
            margin-top: 3px;
            text-transform: uppercase;
        }

        span {
            width: 222px;
            padding: 0;
            color: $gray;
            font-size: 11px;
            overflow: hidden;
            position: relative;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: uppercase;
            font-family: AcuminLight;
            text-align: left;

            @include large {
                width: 200px;
            }

            @include tablet {
                width: 190px;
            }
            //
            @include phone{
                width: 170px;
                font-size: 10px;
            }
        }
    }

    .priceWrapper {
        gap: 5px;
        display: flex;
        margin-top: 5px;
        padding-left: 10px;
        align-items: center;

        .price1 {
            color: $black;
            font-size: 13px;
        }
        .price3 {
            color: $black;
            font-size: 15px;
        }
    }

    .discountWrapper{
        gap: 10px;
        display: flex;
        margin-top: 5px;
        padding-left: 10px;

        .discountItem1{
            gap: 2px;
            display: flex;
            color: $black;
            align-items: center;
            position: relative;
            p{
                font-weight: 500;
                font-size: 11px;
                font-family: AcuminLight;
            }
            span{
                font-size: 13px;
                color: $black;
                font-family: AcuminLight;
            }
        }
        .discountItem1:after {
            left: 0;
            top: 40%;
            height: 1px;
            content: "";
            width: 100%;
            display: block;
            position: absolute;
            background: $subHeading;
        }
        .discountItem2{
            gap: 5px;
            display: flex;
            color: $mainRed;
            align-items: center;
            p{
                font-weight: 500;
                font-size: 13px;
            }
            span{
                font-size: 15px;
            }
        }
    }

}


//LIST CARD
.listCard {
    gap: 5px;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    background-color: $dullBgrd;


    .image {
        width: 100%;
        height: 350px;
        overflow: hidden;
        position: relative;

        @include large{
            height: 300px;
        }
        @include tablet{
            height: 330px;
        }
        @include mobile{
            // height: 290px;
            height: 260px;
        }

        &:hover {
            .secImg {
                z-index: 2;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }

        .mainImg {
            z-index: 1;
        }
    }

    .detailsWrapper {
        display: block;
        width: 270px;
        padding: 0px 5px;
        align-items: flex-start;
        flex-direction: column;

        @include tablet{
            width: 220px;
        }
        @include phone{
            width: 200px;
            width: 180px;
            // background-color: yellow;
        }
        
        
        .titles {
            gap: 5px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 3px;

            h3 {
                font-size: 10.5px;
                text-align: center;
                letter-spacing: 0.5px;
                margin-top: 3px;
                text-transform: uppercase;
            }

            span {
                width: 250px;
                padding: 0;
                color: $gray;
                font-size: 11px;
                overflow: hidden;
                position: relative;
                display: inline-block;
                text-align: center;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-transform: uppercase;
                font-family: AcuminLight;
                text-align: left;

                // background-color: pink;

                @include large{
                    width: 200px;
                }
                @include tablet{
                    width: 200px;
                }
                @include phone{
                    width: 175px;
                    font-size: 10px;
                }
        
            }
            
        }

        .discountWrapper{
            gap: 8px;
            display: flex;

            .discountItem1{
                gap: 3px;
                display: flex;
                color: $black;
                align-items: center;
                position: relative;
                p{
                    font-size: 12px;
                    font-family: AcuminLight;

                }
                span{
                    font-size: 13px;
                    color: $black;
                    font-family: AcuminLight;

                }
            }
            .discountItem1:after {
                position: absolute;
                left: 0;
                top: 43%;
                height: 1px;
                background: $subHeading;
                content: "";
                width: 100%;
                display: block;
            }
            .discountItem2{
                gap: 5px;
                display: flex;
                color: $mainRed;
                align-items: center;
                p{
                    font-size: 13px;
                }
                span{
                    font-size: 15px;
                }
            }

        }

        .priceWrapper {
            gap: 5px;
            display: flex;
            align-items: center;

            .price1 {
                color: $black;
                font-size: 13px;
            }
            .price3 {
                color: $black;
                font-size: 15px;
            }
        }

    }

}