@import '../../global.scss';

.topPicks {
    gap: 10px;
    display: flex;
    padding: 10px 100px;
    align-items: center;
    margin-top: 20px;

    @include large{
        gap: 5px;
        padding: 0px 0px;
    }


    .picksWrapper {
        flex: 1;
        gap: 20px;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .text {
            display: flex;
            color: $black;
            align-items: center;
            flex-direction: column;
            position: absolute;

            p {
                color: $mainColor;
                font-size: 38px;
                letter-spacing: 1px;
                font-family: AcuminBold;
                text-shadow: 0.5px 0.5px 1px $deepBlack;
                @include large{
                    font-size: 20px;
                }
                @include tablet{
                    font-size: 22px;
                }
                @include phone{
                    font-size: 18px;
                }
               
            }

            .textBtn {
                width: 210px;
                padding: 13px 10px;
                color: $black;
                display: flex;
                font-size: 15px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 20px;
                letter-spacing: 0.5px;
                align-items: center;
                font-family: AcuminMedium;
                justify-content: center;
                border: 0.5px solid $subHeading;
                background-color: $mainColor;

                @include large{
                    width: 100px;
                    padding: 8px;
                    font-size: 13px;
                    margin-top: 10px;
                }

            }


        }

        .image {
            flex: 1;
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            img {
                width: 100%;
                height: 100%;
                // filter: brightness(80%);
            }

        }
    }

}



// ///////KIDS//////////
.Kids-toppicks {
    gap: 10px;
    display: flex;
    padding: 10px 100px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 50px;

    @include large{
        gap: 10px;
        padding: 0px 0px;
    }
    @include tablet {
        // gap: 0px;
        // flex-direction: column;
        // border-top: 0.4px solid lightgrey;
        // border-bottom: 0.4px solid lightgrey;
    }


    .picksWrapper {
        flex: 1;
        gap: 20px;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .text {
            display: flex;
            color: $black;
            align-items: center;
            flex-direction: column;
            position: absolute;
            // background-color: transparent;

            p {
                color: $mainColor;
                font-size: 38px;
                font-size: 25px;
                letter-spacing: 1px;
                font-family: AcuminBold;
                text-shadow: 1px 1px 2px $deepBlack;
                @include large{
                    font-size: 20px;
                }
                @include tablet{
                    font-size: 16px;
                }
                @include phone{
                    // font-size: 14px;
                    text-align: center;
                }
               
            }

            .textBtn {
                width: 210px;
                padding: 10px 10px;
                color: $black;
                display: flex;
                font-size: 15px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 20px;
                letter-spacing: 0.5px;
                align-items: center;
                font-family: AcuminMedium;
                justify-content: center;
                border: 0.5px solid $subHeading;
                background-color: $mainColor;

                @include large{
                    width: 100px;
                    padding: 8px 10px;
                    font-size: 13px;
                    font-size: 13px;
                    margin-top: 10px;
                }

            }


        }

        .image {
            flex: 1;
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            img {
                width: 100%;
                height: 100%;
                // filter: brightness(90%);
            }

        }
    }

}