@import '../../global.scss';

.kids {
    width: 100%;
    display: flex;
    margin-top: 130px;
    flex-direction: column;

    .mainImage {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-image: url("../../assets//slider/slider1.png");
        // background-color: #E3E4E0;

        @include large {
            margin-top: -5px;
            flex-direction: column-reverse;
        }

        .text {
            flex: 1.3;
            width: 100%;
            display: flex;
            padding: 0px 30px;
            flex-direction: column;

            // background-color: pink;

            @include large {
                margin-top: 100px;
                position: absolute;
            }

            .textWrapper {
                gap: 15px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                @include large {
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                }

                span {
                    color: $mainRed;
                    color: #6d2d4c;
                    // color: $Sale;
                    // color: $black;
                    font-size: 17px;
                    letter-spacing: 1px;
                    font-family: AcuminLight;
                    text-shadow: 1px 1px 2px $offwhite;

                    @include large {
                        font-size: 16px;
                        color: $mainColor;
                        text-shadow: 1px 1px 2px $black;
                    }
                }

                .heading {
                    display: flex;
                    flex-direction: column;

                    @include large {
                        align-items: center;
                        justify-content: center;
                    }

                    h2 {
                        color: $mainRed;
                        color: #6d2d4c;
                        font-size: 48px;
                        letter-spacing: 10px;
                        letter-spacing: 0.5px;
                        font-family: AcuminPro;
                        text-shadow: 1px 1px 2px $offwhite;

                        @include large {
                            font-size: 40px;
                            color: $mainColor;
                            text-shadow: 1px 1px 2px $black;
                        }
                        @include phone {
                            font-size: 35px;
                        }

                    }

                    h3 {
                        color: $mainRed;
                        color: #6d2d4c;
                        // color: $Sale;
                        // color: $black;
                        font-size: 58px;
                        font-family: AcuminPro;
                        text-shadow: 1px 1px 2px $offwhite;

                        @include large {
                            font-size: 44px;
                            margin-top: -5px;
                            color: $mainColor;
                            text-shadow: 1px 1px 2px $black;
                        }
                        @include phone {
                            font-size: 38px;
                        }
                    }
                }

                .kids-slide-link {
                    gap: 20px;
                    color: inherit;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                }

                .home-Buttons {
                    gap: 20px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    // background-color: yellow;

                    @include large{
                        justify-content: center;
                    }

                    @include tablet{
                        gap: 10px;
                        width: 100%;
                        flex-direction: column;
                    }

                    .homeBtn {
                        gap: 10px;
                        width: 220px;
                        padding: 14px;
                        color: $mainColor;
                        display: flex;
                        font-size: 15px;
                        cursor: pointer;
                        letter-spacing: 1px;
                        align-items: center;
                        justify-content: center;
                        border: 0.5px solid #6d2d4c;
                        background-color: #6d2d4c;

                        @include large2 {
                            // padding: 14px;
                            width: 380px;
                            color: $deepBlack;
                            border: 0.5px solid $black;
                            background-color: $mainColor;
                        }
                        @include phone{
                            padding: 12px;
                        }

                        .kidsArrow {
                            display: none;
                        }

                        &:hover {
                            .kidsArrow {
                                display: flex;
                                font-size: 20px;
                                margin-top: -2px;
                            }
                        }
                    }
                }
            }

        }

        .imgWrapper {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            flex-direction: column;
            justify-content: center;

            .kids-image {
                width: 100%;
                height: 100%;

                @include large {
                    filter: brightness(80%);
                }
            }

        }

        .imgWrapper2 {
            display: none;

            // @include tablet {
            //     flex: 1;
            //     width: 100%;
            //     height: 100%;
            //     display: flex;
            //     align-items: center;
            //     position: relative;
            //     flex-direction: column;
            //     justify-content: center;

            //     .kids-image2 {
            //         width: 100%;
            //         height: 100%;

            //         @include large {
            //             width: 600px;
            //         }
            //     }

            // }

        }
    }
}