@import '../../global.scss';

.mainMen {
    width: 100%;
    display: flex;
    margin-top: 130px;
    flex-direction: column;

    // @include large{
    //     margin-top: 115px;
    // }

    .mainImage {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        // height: calc(100vh - 130px);
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-image: url("../../assets/slider/slider2.png");

        @include large {
            margin-top: -5px;
            flex-direction: column-reverse;
        }

        .text {
            flex: 1.3;
            width: 100%;
            display: flex;
            padding: 0px 30px;
            flex-direction: column;

            @include large {
                margin-top: 100px;
                position: absolute;
            }

            .textWrapper {
                gap: 15px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                @include large {
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                }

                span {
                    color: $mainColor;
                    font-size: 18px;
                    letter-spacing: 1px;
                    font-family: AcuminLight;
                    text-shadow: 1px 1px 2px $black;

                    @include large {
                        font-size: 15px;
                        color: $mainColor;
                        text-shadow: 1px 1px 2px $deepBlack;
                    }
                }

                .heading {
                    display: flex;
                    flex-direction: column;

                    @include large {
                        align-items: center;
                        justify-content: center;
                    }

                    h2 {
                        color: $offwhite;
                        font-size: 58px;
                        letter-spacing: 10px;
                        letter-spacing: 0.5px;
                        font-family: AcuminPro;
                        text-shadow: 1px 1px 2px $black;

                        @include large {
                            font-size: 48px;
                            color: $mainColor;
                            text-shadow: 1px 1px 2px $deepBlack;
                        }
                        @include phone {
                            font-size: 35px;
                        }

                    }

                    h3 {
                        color: $offwhite;
                        font-size: 68px;
                        letter-spacing: 10px;
                        letter-spacing: 0.5px;
                        font-family: AcuminPro;
                        text-shadow: 1px 1px 2px $black;

                        @include large {
                            font-size: 50px;
                            margin-top: -10px;
                            color: $mainColor;
                            text-shadow: 1px 1px 2px $deepBlack;
                        }
                        @include phone {
                            font-size: 40px;
                        }

                    }
                }

                .slide-link {
                    color: inherit;
                    text-decoration: none;
                }

                .homeBtn {
                    width: 220px;
                    padding: 14px;
                    color: $deepBlack;
                    display: flex;
                    font-size: 15px;
                    cursor: pointer;
                    font-weight: 500;
                    letter-spacing: 1px;
                    align-items: center;
                    justify-content: center;
                    border: 1.5px solid $subHeading;
                    background-color: $mainColor;
    
                    @include large {
                        border: 0.4px solid $subHeading;
                        
                    }
                }
            }

        }

        .imgWrapper {
            flex: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            flex-direction: column;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;

                @include large {
                    // width: 600px;
                    filter: brightness(80%);
                }
            }

          
        }
    }

}