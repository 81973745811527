@import "../global.scss";

.promo {
    top: 0;
    gap: 18px;
    width: 100%;
    height: 40px;
    display: flex;
    z-index: 3;
    position: fixed;
    padding: 0px 30px;
    align-items: center;
    justify-content: center;
    background-color: $deepBlack;

    @include extralarge {
        // background-color: gray;
    }

    @include large {
        // background-color: green;
    }

    @include large2 {
        // background-color: lightcoral;
    }

    @include tablet {
        // height: 35px;
        padding: 0px 10px;
        // justify-content: space-between;

        // background-color: orange;
    }

    @include mobile {
        // background-color: yellow;
    }

    @include phone {
        // background-color: lightblue;
    }


    .promoLeft {
        gap: 20px;
        display: flex;
        align-items: center;

        .separator {
            font-size: 15px;
            font-weight: 300;
        }

        h4{
            font-size: 21px;
            color: $lime;
            color: $offwhite;
            // font-family: 'Pacifico', cursive;
            font-family: "Great Vibes", cursive;

            // font-family: "Kaushan Script", cursive;
        }

        .itemWrapper {
            gap: 5px;
            color: $lime;
            color: $offwhite;
            display: flex;
            align-items: center;

            .item {
                flex: 1;
                gap: 2px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-weight: 500;
                    font-size: 22px;
                    margin-top: 3px;
                    font-family: AcuminBold;
                }

                p {
                    margin-top: 4px;
                    font-size: 14px;
                    font-weight: 300;
                    font-family: AcuminLight;
                }
            }
        }
    }

    .separator1 {
        color: $lime;
        color: $offwhite;
        font-family: AcuminExtraLight;

        @include tablet {
            display: none;
        }
    }

    .promoRight {
        gap: 3px;
        color: $lime;
        color: $offwhite;
        display: flex;
        align-items: center;

        @include large2 {
            gap: 3px;
            align-items: flex-start;
            flex-direction: column;
        }

        span {
            font-size: 10.5px;
            font-size: 12px;
            letter-spacing: 1px;
            font-family: AcuminBold;
        }

        .textItem {
            gap: 1px;
            display: flex;
            align-items: center;

            h3 {
                font-size: 10px;
                letter-spacing: 1px;
                font-family: AcuminLight;

                @include tablet {
                    font-size: 10px;
                }
            }

            p {
                font-size: 11px;
                letter-spacing: 1px;
                font-family: AcuminLight;

                @include tablet {
                    font-size: 10px;
                }
            }
        }

    }

}