@import '../../global.scss';

/////////////// TUNJOO WOMAN ///////
.tunjoo {
    gap: 20px;
    width: 100%;
    display: flex;
    // padding: 0px 40px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    background-size: cover;
    background-image: url("../../assets/slider/slider4.png");
    // background-color: #c69f59;

    @include large {
        padding: 0px 0px;
        margin-top: 0px;

    }

    .text {
        width: 100%;
        display: flex;
        padding: 0px 30px;
        flex-direction: column;
        // align-items: flex-end;

        // background-color: pink;

        @include large {
            // margin-top: 100px;
            position: absolute;
        }

        .textWrapper{
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: flex-end;

            padding-right: 100px;

            // background-color: pink;

            @include large {
                gap: 10px;
                margin-top: 150px;
                // margin-top: 10px;
                align-items: center;
                justify-content: center;
                padding-right: 0px;
            }

            .heading {
                gap: 5px;
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                @include large {
                    align-items: center;
                    justify-content: center;
                }

                h2 {
                    color: $mainColor;
                    // color: $deepBlack;
                    font-size: 48px;
                    font-family: AcuminPro;
                    text-shadow: 1px 1px 2px $black;

                    @include tablet {
                        font-size: 38px;
                    }
                    @include mobile {
                        font-size: 35px;
                    }

                }

                h3 {
                    color: $mainColor;
                    font-size: 54px;
                    font-family: AcuminPro;
                    text-shadow: 1px 1px 2px $black;

                    @include tablet {
                        font-size: 43px;
                        margin-top: -10px;
                    }
                    @include mobile {
                        font-size: 35px;
                    }
                   
                }
                span{
                    margin-top: -12px;
                    font-size: 13px;
                    color: $mainColor;
                    margin-left: 16px;
                    letter-spacing: 6px;
                    font-family: AcuminLight;
                    text-shadow: 1px 1px 2px $black;

                    @include large{
                        letter-spacing: 4px;
                    }
                }
            }

            .slide-link{
                color: inherit;
                text-decoration: none;
            }

            .homeBtn {
                gap: 10px;
                width: 220px;
                padding: 10px 10px;
                color: $deepBlack;
                display: flex;
                font-size: 15px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 15px;
                letter-spacing: 1px;
                align-items: center;
                justify-content: center;
                border: 0.5px solid $subHeading;
                background-color: $mainColor;

                @include large {
                    width: 200px;
                    margin-top: 0px;
                }

                .kidsArrow {
                    display: none;
                }

                &:hover {
                    .kidsArrow {
                        display: flex;
                        font-size: 20px;
                        margin-top: -2px;
                    }
                }
            }
        }

    }

    .imgWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
            width: 100%;
            // width: 500px;
            height: 100%;
            // height: 500px;

            @include large{
                width: 600px;
                filter: brightness(80%);
            }
            @include tablet{
                width: 100%;
                // filter: brightness(80%);
            }
        }

    }
}

/////////////// TUNJOO MAN////////////
.tunjooKids {
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 0px 40px;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    background-size: cover;
    background-image: url("../../assets/slider/sliderKids.png");
    // background-color: #9E1B32;
    // background-color: $mainRed;

    @include large {
        padding: 0px 0px;

    }

    .text {
        width: 100%;
        display: flex;
        padding: 0px 30px;
        flex-direction: column;
        // align-items: flex-end;

        // background-color: pink;

        @include large {
            // margin-top: 100px;
            position: absolute;
        }

        .textWrapper{
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: flex-end;

            padding-right: 100px;

            // background-color: pink;

            @include large {
                gap: 10px;
                margin-top: 180px;
                // margin-top: 10px;
                align-items: center;
                justify-content: center;
                padding-right: 0px;
            }

            .heading {
                gap: 5px;
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                @include large {
                    align-items: center;
                    justify-content: center;
                }

                h2 {
                    color: $mainColor;
                    // color: $deepBlack;
                    font-size: 43px;
                    font-family: AcuminPro;
                    text-shadow: 1px 1px 2px $black;

                    @include tablet {
                        font-size: 32px;
                    }

                }

                h3 {
                    color: $mainColor;
                    // color: $deepBlack;
                    font-size: 48px;
                    margin-top: -5px;
                    font-family: AcuminPro;
                    text-shadow: 1px 1px 2px $black;

                    @include tablet {
                        font-size: 38px;
                        margin-top: -10px;
                    }
                    @include phone {
                        font-size: 35px;
                    }
                }
                span{
                    margin-top: -12px;
                    font-size: 13px;
                    color: $mainColor;
                    margin-left: 16px;
                    letter-spacing: 6px;
                    font-family: AcuminLight;
                    text-shadow: 1px 1px 2px $black;

                    @include large{
                        letter-spacing: 4px;
                    }
                }
            }

            .slide-link{
                color: inherit;
                text-decoration: none;
            }

            .homeBtn {
                gap: 10px;
                width: 220px;
                padding: 10px 10px;
                color: $deepBlack;
                display: flex;
                font-size: 15px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 15px;
                letter-spacing: 1px;
                align-items: center;
                justify-content: center;
                border: 0.5px solid $subHeading;
                background-color: $mainColor;

                @include large {
                    width: 200px;
                    margin-top: 0px;
                }

                .kidsArrow {
                    display: none;
                }

                &:hover {
                    .kidsArrow {
                        display: flex;
                        font-size: 20px;
                        margin-top: -2px;
                    }
                }
            }
        }

    }

    .imgWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;

            @include large{
                width: 600px;
                filter: brightness(80%);
            }
            @include tablet{
                width: 100%;
                // filter: brightness(80%);
            }
        }

    }
}




/////////////////// TUNJOO KIDS /////////
.tunjooMan {
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 0px 40px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    background-size: cover;
    background-image: url("../../assets/slider/slider4.png");

    @include large {
        padding: 0px 0px;
        margin-top: 0px;

    }

    .text {
        width: 100%;
        display: flex;
        padding: 0px 30px;
        flex-direction: column;
        // align-items: flex-end;

        // background-color: pink;

        @include large {
            // margin-top: 100px;
            position: absolute;
        }

        .textWrapper{
            gap: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: flex-end;

            padding-right: 100px;

            // background-color: pink;

            @include large {
                gap: 10px;
                margin-top: 150px;
                // margin-top: 10px;
                align-items: center;
                justify-content: center;
                padding-right: 0px;
            }

            .heading {
                gap: 5px;
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                @include large {
                    align-items: center;
                    justify-content: center;
                }

                h2 {
                    color: $mainColor;
                    // color: $deepBlack;
                    font-size: 48px;
                    font-family: AcuminPro;
                    text-shadow: 1px 1px 2px $black;

                    @include tablet {
                        font-size: 38px;
                    }
                    @include mobile {
                        font-size: 35px;
                    }

                }

                h3 {
                    color: $mainColor;
                    font-size: 54px;
                    font-family: AcuminPro;
                    text-shadow: 1px 1px 2px $black;

                    @include tablet {
                        font-size: 43px;
                        margin-top: -10px;
                    }
                    @include mobile {
                        font-size: 35px;
                    }
                }
                span{
                    margin-top: -12px;
                    font-size: 13px;
                    color: $mainColor;
                    margin-left: 16px;
                    letter-spacing: 6px;
                    font-family: AcuminLight;
                    text-shadow: 1px 1px 2px $black;

                    @include large{
                        letter-spacing: 4px;
                    }
                }
            }

            .slide-link{
                color: inherit;
                text-decoration: none;
            }

            .homeBtn {
                gap: 10px;
                width: 220px;
                padding: 10px 10px;
                color: $deepBlack;
                display: flex;
                font-size: 15px;
                cursor: pointer;
                font-weight: 500;
                margin-top: 15px;
                letter-spacing: 1px;
                align-items: center;
                justify-content: center;
                border: 0.5px solid $subHeading;
                background-color: $mainColor;

                @include large {
                    width: 200px;
                    margin-top: 0px;
                }

                .kidsArrow {
                    display: none;
                }

                &:hover {
                    .kidsArrow {
                        display: flex;
                        font-size: 20px;
                        margin-top: -2px;
                    }
                }
            }
        }

    }

    .imgWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;

            @include large{
                width: 600px;
                filter: brightness(80%);
            }
            @include tablet{
                width: 100%;
                // filter: brightness(80%);
            }
        }

    }
}