@import '../../global.scss';

.drawer {
    top: 65px;
    // width: 280px;
    flex: 1.5;
    display: flex;
    position: sticky;
    border-radius: 3px;
    flex-direction: column;
    height: calc(100vh - 90px);
    border: 0.4px solid lightgray;
    justify-content: space-between;

    .navLogoWrapper {
        display: flex;
        height: 120px;
        align-items: center;
        justify-content: center;

        .navigationLogo {
            width: 110px;
            height: 110px;
            cursor: pointer;
        }

    }

    .listItemWrapper {
        display: flex;
        height: calc(100vh - 130px);
        flex-direction: column;
        justify-content: space-between;

        .itemsWrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .adminFooter {
            width: 100%;
            display: flex;
            cursor: pointer;
            align-items: flex-end;
            justify-content: center;

            .adminFooterItem {
                gap: 12px;
                width: 100%;
                display: flex;
                cursor: pointer;
                color: $subHeading;
                padding: 13px 10px;
                align-items: center;
                justify-content: center;
                border: 0.4px solid lightgray;

                &:hover {
                    color: crimson;
                    background-color: lightgrey;
                }
    
                span {
                    flex: 1;
                    font-size: 16px;
                    margin-top: 4px;
                    font-family: AcuminBold;
                }
    
                .adminFooterIcon {
                    flex: 0.5;
                    font-size: 20px;
                }

            }

        }
    }


}