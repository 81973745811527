@import '../../global.scss';

// ----------- ACCOUNT SIDEBAR -----------
.accMainSidebar {
    flex: 1.5;
    top: 95px;
    display: flex;
    position: sticky;
    border-radius: 3px;
    flex-direction: column;
    height: calc(100vh - 120px);
    border: 0.4px solid lightgray;
    justify-content: space-between;

    @include large2 {
        flex: 2;
    }

    @include tablet {
        flex-wrap: wrap;
        position: static;
    }

    .sideInfo {

        .top {
            gap: 10px;
            display: flex;
            padding: 20px 30px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-bottom: 0.4px solid lightgray;

            img {
                width: 100px;
                height: 100px;
            }

            p {
                font-size: 15px;
                font-family: AcuminLight;
            }
        }

        .bottom {
            width: 100%;
            display: flex;
            list-style: none;
            margin-bottom: 10px;
            flex-direction: column;

        }
    }

    .sideFooter {
        width: 100%;
        display: flex;
        cursor: pointer;
        align-items: flex-end;
        justify-content: center;

        .sideFooterItem {
            gap: 12px;
            width: 100%;
            display: flex;
            cursor: pointer;
            color: $subHeading;
            padding: 13px 10px;
            align-items: center;
            justify-content: center;
            border-top: 0.4px solid lightgray;
            border-bottom: 0.4px solid lightgray;

            &:hover {
                color: crimson;
                background-color: lightgrey;
            }

            span {
                flex: 1;
                font-size: 14.5px;
                margin-top: 4px;
                font-family: AcuminBold;
            }

            .signoutIcon {
                flex: 0.5;
                font-size: 20px;
            }

        }

    }
}

// ----------- ACCOUNT INFO -----------
.accountInfoWrapper {
    gap: 30px;
    display: flex;
    padding: 20px 40px;
    flex-direction: column;

    .left {
        gap: 20px;
        display: flex;
        flex-direction: column;

        .top {
            gap: 3px;
            display: flex;
            flex-direction: column;

            h3 {
                font-size: 18px;
                color: $black;
            }

            h4 {
                font-size: 13px;
                color: $gray;
                letter-spacing: 0.6px;
                font-family: AcuminExtraLight;
            }
        }

        .bottom {
            gap: 13px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .item {
                gap: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    flex: 1;
                    font-size: 14.5px;
                    letter-spacing: 0.4px;
                }

                p {
                    flex: 1;
                    font-size: 15px;
                    font-family: AcuminLight;
                }
            }
        }

    }

    .divider {
        width: 100%;
        margin-top: 3px;
        margin-bottom: 3px;
        border: 0.7px solid lightgray;
    }

    .right {
        gap: 20px;
        display: flex;
        color: $subHeading;
        flex-direction: column;

        .top {
            gap: 3px;
            display: flex;
            flex-direction: column;

            h3 {
                font-size: 18px;
                color: $black;
            }

            h4 {
                font-size: 13px;
                color: $gray;
                letter-spacing: 0.6px;
                font-family: AcuminExtraLight;
            }
        }

        .bottom {
            gap: 13px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .formInput {
                gap: 5px;
                display: flex;
                flex-direction: column;

                label {
                    font-size: 14.5px;
                    letter-spacing: 0.4px;
                }

                input {
                    width: 350px;
                    padding: 8px 5px;
                    height: 40px;
                    border-radius: 3px;
                    color: $subHeading;
                    border: 0.5px solid lightgray;

                    @include tablet {
                        width: 100%;
                    }

                    @include mobile {
                        width: 350px;
                        height: 50px;
                    }
                }

                input::placeholder {
                    font-size: 14px;
                    // font-family: AcuminLight;

                    @include phone {
                        font-size: 15px;
                        color: gray;
                    }
                }

                input:focus {
                    outline: none;
                    background-color: white;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-text-fill-color: $black;
                    transition: background-color 5000s ease-in-out 0s;
                }
            }

            .formInput2 {
                gap: 5px;
                display: none;
                flex-direction: column;

            }

            .formButton {
                width: 350px;
                padding: 8px 5px;
                height: 40px;
                border: none;
                font-size: 16px;
                color: $mainColor;
                cursor: pointer;
                // margin-top: 40px;
                border-radius: 3px;
                align-self: center;
                background-color: $black;

                @include tablet {
                    width: 180px;
                    margin-top: 20px;
                }
            }
        }


    }
}


// ----------- ACCOUNT WISHLIST -----------
.accountWishlist {
    gap: 30px;
    color: $black;
    display: flex;
    padding: 20px 40px;
    flex-direction: column;

    @include extralarge {
        padding: 20px 20px;
    }

    .wishlistLeft {
        gap: 15px;
        display: flex;
        border-radius: 5px;
        padding: 15px;
        flex-direction: column;
        background-color: $mainColor;
        border: 0.4px solid lightgray;

        h3 {
            font-size: 18px;
            letter-spacing: 0.5px;
            font-family: AcuminMedium;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.6px;
            font-family: AcuminLight;
        }

    }

    .wishlistRight {
        gap: 10px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 18px;
            letter-spacing: 0.5px;
            font-family: AcuminMedium;
        }

        .divider {
            width: 100%;
            border: 0.4px dotted lightgray;
        }

        .itemsWrapper {
            gap: 10px;
            width: 100%;
            padding: 10px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            background-color: $mainColor;

            @include large {
                grid-template-columns: repeat(1, 1fr);
            }

        }
    }

}

// ----------- ACCOUNT WALLET -----------
.accWallet {
    gap: 30px;
    width: 100%;
    display: flex;
    padding: 20px 40px;
    flex-direction: column;

    @include large {
        padding: 20px 20px;
    }

    .accTop {
        gap: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include extralarge {
            gap: 30px;
        }

        @include large2 {
            gap: 10px;
            flex-direction: column;
        }


        .left {
            flex: 1;
            width: 100%;
            height: 170px;
            color: $black;
            padding: 15px 12px;
            border-radius: 10px;
            background-color: $mainColor;
            border: 1px solid $mainBlueLite2;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

            @include large2 {
                padding: 15px 16px;
            }

            h3 {
                color: $black;
                font-size: 18px;
                letter-spacing: 0.5px;
                font-family: AcuminMedium;
            }

            p {
                font-size: 13px;
                color: gray;
                margin-bottom: 5px;
                letter-spacing: 0.6px;
                font-family: AcuminLight;
            }

            .accDivider {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
                margin-bottom: 10px;
                border: 0.7px solid lightgray;
            }

            .accBalance {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                justify-content: space-between;

                h2 {
                    font-size: 15.5px;
                    font-family: AcuminMedium;
                }

                .amount {
                    gap: 5px;
                    display: flex;
                    align-items: center;

                    h2 {
                        font-size: 18px;
                        font-family: AcuminMedium;
                    }

                    span {
                        font-size: 13px;
                        letter-spacing: 0.6px;
                        font-family: AcuminLight;
                    }

                }
            }

            .accBtns {
                gap: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .accBtnIcon {
                    margin-top: -3px;
                }

                h4 {
                    letter-spacing: 0.5px;
                }

                .depositBtn {
                    flex: 1;
                    gap: 5px;
                    border: none;
                    display: flex;
                    color: $mainColor;
                    padding: 12px 0px;
                    align-items: center;
                    justify-content: center;
                    background-color: $moneyGreen;
                }

                .transferBtn {
                    flex: 1;
                    gap: 5px;
                    border: none;
                    display: flex;
                    color: $mainColor;
                    padding: 12px 0px;
                    align-items: center;
                    justify-content: center;
                    background-color: $mainBlue;
                }
            }
        }

        .right {
            flex: 1;
            gap: 10px;
            width: 100%;
            height: 170px;
            padding: 12px;
            color: $black;
            display: flex;
            border-radius: 10px;
            flex-direction: column;
            background-color: $mainColor;
            border: 1px solid $mainBlueLite2;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

            @include large2 {
                padding: 15px 16px;
            }

            .rightTop {
                display: flex;
                align-items: center;

                img {
                    width: 60px;
                    height: 60px;
                }

                span {
                    font-size: 22px;
                    font-family: AcuminLight;
                }
            }

            .rightBottom {
                gap: 10px;
                display: flex;
                align-items: center;

                h4 {
                    font-size: 18px;
                    font-family: AcuminLight;
                }

                .giftIcon {
                    font-size: 20px;
                    color: $mainBlue;
                    margin-top: -10px;
                }
            }

            .accFooter {
                display: flex;

                p {
                    font-size: 13px;
                    color: gray;
                    margin-bottom: 5px;
                    letter-spacing: 0.6px;
                    font-family: AcuminLight;
                }
            }
        }

    }

    .accDivider2 {
        width: 100%;
        border: 0.7px solid lightgray;
    }

    .accBottom {
        display: flex;

        h3 {
            font-size: 18px;
            font-family: AcuminLight;
        }
    }
}

// ----------- ACCOUNT ORDERS -----------
.accountOrders {
    gap: 30px;
    width: 100%;
    display: flex;
    padding: 20px 20px;
    flex-direction: column;

    h3 {
        font-size: 18px;
        color: $subHeading;
        font-family: AcuminLight;
    }

    .table {
        width: 100%;
        overflow-x: scroll;
        border: 1px solid lightgray;

        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            background-clip: content-box;
            box-shadow: inset 0 0 3px grey;
        }

        ::-webkit-scrollbar-thumb {
            background: #6E6E6E;
            background-color: rgba(110, 110, 110, 0.2);
        }

        @include tablet {
            width: 95%;
        }

        @include mobile {
            width: 85%;
        }

        .tableCellTitle {
            font-weight: 500;
            color: $black;
        }

        .tableCell {
            color: $black;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .orderImg {
                width: 35px;
                height: 35px;
            }
        }

        .payment_type {
            border-radius: 3px;

            &.mobilemoneyug {
                text-align: center;
                padding: 8px 10px;
                color: goldenrod;
                font-family: AcuminLight;
                background-color: rgba(225, 225, 79, 0.277);

            }

            &.card {
                padding: 8px 45px;
                color: rgb(10, 10, 137);
                background-color: rgba(152, 155, 241, 0.385);
            }

        }

        .status {

            &.Placed {
                color: $mainBlue;
            }

            &.Moving {
                color: goldenrod;
            }

            &.Delivered {
                color: $moneyGreen;
            }

        }

        .viewButton {
            display: flex;
            cursor: pointer;
            padding: 5px 0px;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            border: 1px dotted $moneyGreen;
            background-color: $moneyGreenLite;

        }

    }

}

// ----------- SINGLE ORDER -----------
.singleOrder {
    gap: 15px;
    width: 100%;
    display: flex;
    padding: 20px 20px;
    flex-direction: column;

    .singleOrderlink {
        color: inherit;
        text-decoration: none;
    }

    hr {
        width: 100%;
        margin-top: 3px;
        margin-bottom: 3px;
        border: 0.4px solid lightgray;
    }

    .backWrapper {
        gap: 5px;
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
            color: $mainBlue;
            font-family: AcuminLight;
        }

        .backIcon {
            font-size: 15px;
            color: $mainBlue;
            font-family: AcuminLight;
        }
    }

    .orderTop {
        gap: 5px;
        display: flex;
        flex-direction: column;

        h3 {
            color: $subHeading;
            font-size: 19px;
            letter-spacing: 0.6px;
            font-family: AcuminLight;
        }

        span {
            color: $gray2;
            font-size: 15px;
            font-family: AcuminLight;
        }

        .totalWrapper {
            gap: 10px;
            display: flex;
            align-items: center;

            h4 {
                font-size: 18px;
                color: $black;
                font-family: AcuminLight;
            }

            .totalAmt {
                gap: 3px;
                display: flex;

                p {
                    font-size: 17px;
                    margin-top: 1px;
                    color: $black;
                }

                span {
                    font-size: 18.5px;
                    color: $black;
                    font-family: AcuminMedium;
                }
            }
        }

    }

    .orderDetailsWrapper {
        gap: 20px;
        display: flex;

        .statusWrapper {
            flex: 1;
            gap: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span {
                color: $subHeading;
                font-size: 19px;
                letter-spacing: 0.6px;
                font-family: AcuminLight;
            }

            .bottom {
                flex: 2;
                gap: 5px;
                display: flex;

                .item {
                    flex: 1;
                    gap: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $mainColor;
                    border: 0.3px solid lightgray;

                    &.Placed,
                    &.Moving,
                    &.Delivered {
                        background-color: $moneyGreen;
                    }


                    .statusText {
                        font-size: 15px;
                        color: $subHeading;
                        letter-spacing: 0.7px;
                        font-family: AcuminLight;

                        &.Placed,
                        &.Moving,
                        &.Delivered {
                            color: $mainColor;
                        }
                    }

                    .statusIcon {
                        margin-top: -5px;
                        color: $subHeading;

                        &.Placed,
                        &.Moving,
                        &.Delivered {
                            color: $mainColor;
                        }
                    }


                }
            }
        }

        .addressWrapper {
            flex: 1;
            gap: 5px;
            display: flex;
            flex-direction: column;

            .name {
                gap: 8px;
                display: flex;
                align-items: center;

                p {
                    color: $gray;
                    font-size: 13.5px;
                    font-family: AcuminLight;
                }

                span {
                    font-size: 15.5px;
                    color: $subHeading;
                    letter-spacing: 0.5px;
                    font-family: AcuminMedium;
                }
            }

            .addDetails {
                gap: 5px;
                display: flex;
                flex-direction: column;

                p {
                    color: $gray;
                    font-size: 14px;
                    font-family: AcuminLight;
                }
            }

        }
    }

    .orderProducts {
        gap: 10px;
        display: flex;
        flex-direction: column;

        .productHr {
            width: 100%;
            margin-top: 1px;
            margin-bottom: 1px;
            border: 0.4px solid lightgray;
        }

        .top {
            display: flex;

            .item {
                flex: 0.7;

                span {
                    font-size: 15px;
                    color: $black;
                    font-family: AcuminLight;
                }
            }

            .desc {
                flex: 2;

                span {
                    font-size: 15px;
                    color: $black;
                    font-family: AcuminLight;
                }
            }

            .price {
                flex: 0.5;

                span {
                    font-size: 15px;
                    color: $black;
                    font-family: AcuminLight;
                }
            }
        }

        .orderBottom {
            display: flex;

            .left {
                flex: 0.7;

                .orderImg {
                    width: 130px;
                    height: 150px;
                }
            }

            .center {
                flex: 2;
                gap: 10px;
                display: flex;
                flex-direction: column;

                h4 {
                    font-size: 15px;
                    letter-spacing: 0.5px;
                }

                .centerItem {
                    gap: 8px;
                    display: flex;

                    span {
                        color: $gray;
                        font-size: 14px;
                        font-family: AcuminLight;
                    }

                    p {
                        color: $black;
                        font-size: 14px;
                        font-family: AcuminMedium;
                    }

                    .priceItem {
                        gap: 5px;
                        display: flex;

                        span {
                            color: $black;
                            font-size: 14px;
                            font-family: AcuminMedium;
                        }

                        p {
                            color: $black;
                            font-size: 14px;
                            font-family: AcuminMedium;
                        }
                    }
                }
            }

            .right {
                flex: 0.5;
                gap: 5px;
                display: flex;

                h4 {
                    color: $black;
                    font-size: 16.5px;
                    font-family: AcuminMedium;
                }

                p {
                    color: $black;
                    font-size: 15.5px;
                    margin-top: 1px;
                    font-family: AcuminMedium;
                }
            }
        }
    }

}

// ----------- ACCOUNT PROMO -----------


