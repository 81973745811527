@import '../../global.scss';

.responseNav {
    display: none;

    @include large {
        top: 40px;
        height: 50px;
        display: flex;
        z-index: 3;
        width: 100%;
        position: fixed;
        padding: 0px 10px;
        align-items: center;
        background-color: $mainColor;
        justify-content: space-between;
        border-bottom: 0.4px solid lightgray;
    }

    @include tablet {
        // top: 35px;
    }

    .left {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .menuIcon {
            color: $black;
            display: block;
            cursor: pointer;
            font-size: 20px;
            margin-top: 2px;
        }

        span {
            color: $black;
            font-size: 22px;
            letter-spacing: -0.8px;
            font-family: 'DM Serif Display', serif;

            @include phone{
                font-size: 26px;
            }
        }
    }


    .right {
        gap: 5px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        // background-color: pink;

        // .searchWrapper {
        //     flex: 2.5;
        //     width: 100%;
        //     display: flex;
        //     border-radius: 5px;
        //     align-items: center;
        //     background-color: white;
        //     justify-content: space-between;
        //     border: 0.5px solid lightgray;

        //     input {
        //         width: 100%;
        //         height: 100%;
        //         padding: 10px;
        //         font-size: 14px;
        //         color: #282828;
        //         border-top-left-radius: 5px;
        //         border-bottom-left-radius: 5px;
        //         border: 0.5px solid lightgray;
        //     }

        //     input::placeholder {
        //         font-size: 13px;
        //     }

        //     input:focus {
        //         outline: none;
        //     }

        //     .searchIcon {
        //         display: flex;
        //         height: 100%;
        //         padding: 10px;
        //         font-size: 16px;
        //         color: #fbfbfd;
        //         align-items: center;
        //         justify-content: center;
        //         border-top-right-radius: 5px;
        //         border-bottom-right-radius: 5px;
        //         background-color: $black;
        //         border: 0.5px solid $black;
        //     }
        // }

        .icons {
            gap: 10px;
            display: flex;
            color: $black;
            align-items: center;
            height: 100%;
            justify-content: space-between;

            .activeUserWrapper {
                gap: 5px;
                display: flex;
                height: 100%;
                cursor: pointer;
                align-items: center;

                .icon {
                    font-size: 18px;
                }

                .secIcon {
                    margin-top: 3px;
                    color: $lime;

                    color: $deepBlack;
                }

                span {
                    font-size: 14px;
                    margin-top: 5px;
                    letter-spacing: 0.4px;
                    font-family: AcuminLight;
                }

                h3 {
                    font-weight: 300;
                    font-size: 14px;
                    margin-top: 5px;
                    color: $mainBlue;
                }
            }

            .iconWrapper {
                gap: 10px;
                display: flex;
                height: 100%;
                cursor: pointer;
                align-items: center;

                .likeIcon {
                    display: flex;
                    align-items: center;
                    position: relative;

                    .icon {
                        font-size: 19px;
                        margin-top: 3px;
                        cursor: pointer;
                        font-family: AcuminLight;
                    }

                    span {
                        font-size: 11px;
                        margin-top: 4px;
                        margin-left: 6.5px;
                        color: $lime;

                        color: $deepBlack;
                        position: absolute;
                    }
                }

                .cartIcon {
                    display: flex;
                    position: relative;
                    align-items: center;
                    cursor: pointer;
                    justify-content: center;

                    .icon {
                        font-size: 22px;
                    }

                    .searchIcon {
                        font-size: 20px;
                        margin-top: 6px;
                    }

                    span {
                        top: 8px;
                        display: flex;
                        font-size: 11px;
                        color: $black;
                        align-items: center;
                        position: absolute;
                        justify-content: center;
                    }

                }
            }

        }

        hr {
            width: 100%;
            display: flex;
            margin-top: 5px;
            margin-bottom: 5px;
            border: 0.4px solid lightgray;
        }

        .options {
            top: 50px;
            gap: 5px;
            z-index: 1;
            width: 180px;
            color: $black;
            display: flex;
            list-style: none;
            padding: 10px 5px;
            position: absolute;
            border-radius: 3px;
            flex-direction: column;
            background-color: $mainColor;

            float: right;
            right: 0;

            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

            .optionsLink {
                color: inherit;
                text-decoration: none;
            }

            .menuItem {
                cursor: pointer;
                padding: 10px 10px;

                &:hover {
                    color: $mainBlue;
                    background-color: lightgrey;
                }

                &:focus {
                    background-color: lightgrey;

                    @include tablet {
                        border: none;
                    }
                }

                span {
                    font-size: 14px;
                    font-family: AcuminLight;
                }

                h3 {
                    color: $subHeading;
                    font-size: 14px;
                    font-family: AcuminBold;
                }
            }

            .menuItem2 {
                gap: 10px;
                display: flex;
                padding: 10px 10px;
                // justify-content: space-between;

                .optionBtn1 {
                    width: 100%;
                    font-size: 13px;
                    cursor: pointer;
                    padding: 10px 10px;
                    color: $subHeading;
                    border-radius: 5px;
                    font-family: AcuminLight;
                    border: 0.4px solid $black;
                    background-color: $mainColor;

                    &:hover {
                        border: 0.4px solid $gray;
                    }
                }

                .optionBtn2 {
                    width: 100%;
                    border: none;
                    font-size: 13px;
                    cursor: pointer;
                    padding: 10px 10px;
                    color: $mainColor;
                    border-radius: 5px;
                    font-family: AcuminLight;
                    background-color: $black;

                    &:hover {
                        background-color: $subHeading;
                    }
                }

            }

            .menuItem3 {
                cursor: pointer;
                padding: 10px 10px;
                color: $subHeading;

                &:hover {
                    background-color: lightgrey;

                    h3 {
                        color: crimson;
                    }
                }

                &:focus {
                    background-color: lightgrey;

                    @include tablet {
                        border: none;
                    }
                }

                h3 {
                    font-size: 14px;
                    font-family: AcuminBold;
                }
            }

        }

    }


    //////////// menu /////////

    .menu {
        top: 90px;
        // top: 150px;
        margin: 0;
        // width: 50vw;
        // z-index: 9999;
        width: 100vw;
        z-index: 999;
        display: flex;
        position: fixed;
        color: $black;
        list-style: none;
        flex-direction: column;
        transition: all 1s ease;
        // padding: 0px 5px 0px 10px;
        padding: 0px 5px;
        height: calc(100vh - 85px);
        background-color: $mainColor;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px;
        justify-content: space-between;


        .cats {
            top: 92px;
            gap: 3px;
            width: 97.5%;
            display: flex;
            align-items: center;
            justify-content: center;

            position: fixed;
            height: 150px;

            .module-listItem {
                flex: 1;
                width: 100%;
                display: flex;
                cursor: pointer;
                align-items: center;
                justify-content: center;

                height: 100%;

                &.active {
                    p {
                        color: $mainColor;
                        font-size: 14px;
                        position: absolute;
                        font-family: AcuminBold;
                        text-shadow: 1px 1px 1px $black;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                p {
                    color: $mainColor;
                    font-size: 14px;
                    position: absolute;
                    font-family: AcuminLight;
                    text-shadow: 1px 1px 1px $black;
                }
            }

        }

        // .resTop{
        //     overflow-y: auto;
        //     display: flex;
        //     flex-direction: column;

        .resTop {
            margin-top: 110px;
            margin-top: 155px;
            height: 100%;
            overflow-y: scroll;
            height: 460px;

            ::-webkit-scrollbar {
                width: 10px;
            }
            ::-webkit-scrollbar-track {
                background-clip: content-box; 
                box-shadow: inset 0 0 3px gray; 
            }
            ::-webkit-scrollbar-thumb {
                background: #6E6E6E; 
                background-color: rgba(110,110,110, 0.2);
            }

            .resLinksWrapper {
                gap: 3px;
                display: flex;
                list-style: none;
                flex-direction: column;
                // overflow: auto;
                // height: 100%;


                .res-link {
                    color: inherit;
                    text-decoration: none;
                }

                .women-home-item {
                    gap: 5px;
                    display: flex;
                    color: inherit;
                    cursor: pointer;
                    align-items: center;
                    text-decoration: none;
                    justify-content: flex-start;
                    padding: 12px 0px 12px 10px;
                    background-color: #FFD1DC;

                    .sideHomeIcon {
                        color: $deepBlack;
                        font-size: 18px;
                        margin-top: -2px;
                    }

                    span {
                        color: $deepBlack;
                        font-size: 15px;
                        margin-top: 5px;
                        letter-spacing: 0.4px;
                    }
                }

                .men-home-item {
                    gap: 5px;
                    display: flex;
                    color: inherit;
                    cursor: pointer;
                    align-items: center;
                    text-decoration: none;
                    background-color: #89CFF0;
                    justify-content: flex-start;
                    padding: 12px 0px 12px 10px;

                    .sideHomeIcon {
                        color: $deepBlack;
                        font-size: 18px;
                        margin-top: -2px;
                    }

                    span {
                        color: $deepBlack;
                        font-size: 15px;
                        margin-top: 5px;
                        letter-spacing: 0.4px;
                    }
                }

                .kids-home-item {
                    gap: 5px;
                    display: flex;
                    color: inherit;
                    cursor: pointer;
                    align-items: center;
                    text-decoration: none;
                    background-color: $lime;
                    justify-content: flex-start;
                    padding: 12px 0px 12px 10px;

                    .sideHomeIcon {
                        color: $deepBlack;
                        font-size: 18px;
                        margin-top: -2px;
                    }

                    span {
                        color: $deepBlack;
                        font-size: 15px;
                        margin-top: 5px;
                        letter-spacing: 0.4px;
                    }
                }

                .sideLinks {
                    display: flex;
                    color: inherit;
                    align-items: center;
                    padding: 13px 10px;
                    text-decoration: none;
                    background-color: $cardBack;
                    background-color: #D1D0CE;
                    justify-content: space-between;

                    span {
                        color: $black;
                        font-size: 15px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                    }

                    .dropIcon {
                        font-size: 19px;
                        cursor: pointer;
                    }

                }

                .options22 {
                    gap: 3px;
                    display: flex;
                    flex-direction: column;

                    .sideLink2 {
                        display: flex;
                        color: inherit;
                        align-items: center;
                        padding: 10px;
                        text-decoration: none;
                        background-color: $cardBack;
                        justify-content: space-between;

                        p {
                            color: $black;
                            font-size: 16px;
                            letter-spacing: 0.4px;
                            text-transform: capitalize;
                        }

                        .dropIcon {
                            font-size: 19px;
                            cursor: pointer;
                            margin-top: -3px;
                        }

                        // }

                    }

                }

            }

            .resText {
                gap: 5px;
                // margin-top: -72px;
                display: flex;
                margin-bottom: 10px;
                align-items: center;
                justify-content: center;
                text-align: center;

                position: fixed;
                bottom: 0;


                p {
                    text-align: center;
                    font-size: 11.5px;
                    font-family: AcuminLight;
                }
            }

        }

    }















// @import '../../global.scss';

// .responseNav {
//     display: none;

//     @include large {
//         top: 40px;
//         height: 50px;
//         display: flex;
//         z-index: 3;
//         width: 100%;
//         position: fixed;
//         padding: 0px 10px;
//         align-items: center;
//         background-color: $mainColor;
//         justify-content: space-between;
//         border-bottom: 0.4px solid lightgray;
//     }

//     @include tablet {
//         // top: 35px;
//     }

//     .left {
//         gap: 10px;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .menuIcon {
//             color: $black;
//             display: block;
//             cursor: pointer;
//             font-size: 20px;
//             margin-top: 2px;
//         }

//         span {
//             color: $black;
//             font-size: 22px;
//             letter-spacing: -0.8px;
//             font-family: 'DM Serif Display', serif;
//         }
//     }


//     .right {
//         gap: 5px;
//         height: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         // background-color: pink;

//         // .searchWrapper {
//         //     flex: 2.5;
//         //     width: 100%;
//         //     display: flex;
//         //     border-radius: 5px;
//         //     align-items: center;
//         //     background-color: white;
//         //     justify-content: space-between;
//         //     border: 0.5px solid lightgray;

//         //     input {
//         //         width: 100%;
//         //         height: 100%;
//         //         padding: 10px;
//         //         font-size: 14px;
//         //         color: #282828;
//         //         border-top-left-radius: 5px;
//         //         border-bottom-left-radius: 5px;
//         //         border: 0.5px solid lightgray;
//         //     }

//         //     input::placeholder {
//         //         font-size: 13px;
//         //     }

//         //     input:focus {
//         //         outline: none;
//         //     }

//         //     .searchIcon {
//         //         display: flex;
//         //         height: 100%;
//         //         padding: 10px;
//         //         font-size: 16px;
//         //         color: #fbfbfd;
//         //         align-items: center;
//         //         justify-content: center;
//         //         border-top-right-radius: 5px;
//         //         border-bottom-right-radius: 5px;
//         //         background-color: $black;
//         //         border: 0.5px solid $black;
//         //     }
//         // }

//         .icons {
//             gap: 10px;
//             display: flex;
//             color: $black;
//             align-items: center;
//             height: 100%;
//             justify-content: space-between;

//             .activeUserWrapper {
//                 gap: 5px;
//                 display: flex;
//                 height: 100%;
//                 cursor: pointer;
//                 align-items: center;

//                 .icon {
//                     font-size: 18px;
//                 }

//                 .secIcon {
//                     margin-top: 3px;
//                     color: $lime;

//                     color: $deepBlack;
//                 }

//                 span {
//                     font-size: 14px;
//                     margin-top: 5px;
//                     letter-spacing: 0.4px;
//                     font-family: AcuminLight;
//                 }

//                 h3 {
//                     font-weight: 300;
//                     font-size: 14px;
//                     margin-top: 5px;
//                     color: $mainBlue;
//                 }
//             }

//             .iconWrapper {
//                 gap: 10px;
//                 display: flex;
//                 height: 100%;
//                 cursor: pointer;
//                 align-items: center;

//                 .likeIcon {
//                     display: flex;
//                     align-items: center;
//                     position: relative;

//                     .icon {
//                         font-size: 19px;
//                         margin-top: 3px;
//                         cursor: pointer;
//                         font-family: AcuminLight;
//                     }

//                     span {
//                         font-size: 11px;
//                         margin-top: 4px;
//                         margin-left: 6.5px;
//                         color: $lime;

//                         color: $deepBlack;
//                         position: absolute;
//                     }
//                 }

//                 .cartIcon {
//                     display: flex;
//                     position: relative;
//                     align-items: center;
//                     cursor: pointer;
//                     justify-content: center;

//                     .icon {
//                         font-size: 22px;
//                     }

//                     .searchIcon {
//                         font-size: 20px;
//                         margin-top: 6px;
//                     }

//                     span {
//                         top: 8px;
//                         display: flex;
//                         font-size: 11px;
//                         color: $black;
//                         align-items: center;
//                         position: absolute;
//                         justify-content: center;
//                     }

//                 }
//             }

//         }

//         hr {
//             width: 100%;
//             display: flex;
//             margin-top: 5px;
//             margin-bottom: 5px;
//             border: 0.4px solid lightgray;
//         }

//         .options {
//             top: 50px;
//             gap: 5px;
//             z-index: 1;
//             width: 180px;
//             color: $black;
//             display: flex;
//             list-style: none;
//             padding: 10px 5px;
//             position: absolute;
//             border-radius: 3px;
//             flex-direction: column;
//             background-color: $mainColor;
//             box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

//             .optionsLink {
//                 color: inherit;
//                 text-decoration: none;
//             }

//             .menuItem {
//                 cursor: pointer;
//                 padding: 10px 10px;

//                 &:hover {
//                     color: $mainBlue;
//                     background-color: lightgrey;
//                 }

//                 &:focus {
//                     background-color: lightgrey;

//                     @include tablet {
//                         border: none;
//                     }
//                 }

//                 span {
//                     font-size: 14px;
//                     font-family: AcuminLight;
//                 }

//                 h3 {
//                     color: $subHeading;
//                     font-size: 14px;
//                     font-family: AcuminBold;
//                 }
//             }

//             .menuItem2 {
//                 gap: 10px;
//                 display: flex;
//                 padding: 10px 10px;
//                 // justify-content: space-between;

//                 .optionBtn1 {
//                     width: 100%;
//                     font-size: 13px;
//                     cursor: pointer;
//                     padding: 10px 10px;
//                     color: $subHeading;
//                     border-radius: 5px;
//                     font-family: AcuminLight;
//                     border: 0.4px solid $black;
//                     background-color: $mainColor;

//                     &:hover {
//                         border: 0.4px solid $gray;
//                     }
//                 }

//                 .optionBtn2 {
//                     width: 100%;
//                     border: none;
//                     font-size: 13px;
//                     cursor: pointer;
//                     padding: 10px 10px;
//                     color: $mainColor;
//                     border-radius: 5px;
//                     font-family: AcuminLight;
//                     background-color: $black;

//                     &:hover {
//                         background-color: $subHeading;
//                     }
//                 }

//             }

//             .menuItem3 {
//                 cursor: pointer;
//                 padding: 10px 10px;
//                 color: $subHeading;

//                 &:hover {
//                     background-color: lightgrey;

//                     h3 {
//                         color: crimson;
//                     }
//                 }

//                 &:focus {
//                     background-color: lightgrey;

//                     @include tablet {
//                         border: none;
//                     }
//                 }

//                 h3 {
//                     font-size: 14px;
//                     font-family: AcuminBold;
//                 }
//             }

//         }

//     }

//     .menu {
//         top: 90px;
//         margin: 0;
//         // width: 50vw;
//         // z-index: 9999;
//         width: 100vw;
//         z-index: 999;
//         display: flex;
//         position: fixed;
//         color: $black;
//         overflow-y: auto;
//         list-style: none;
//         flex-direction: column;
//         transition: all 1s ease;
//         padding: 0px 5px 0px 10px;
//         height: calc(100vh - 85px);
//         background-color: $mainColor;
//         box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px;

//         justify-content: space-between;
//         overflow-y: scroll;

//         .cats {
//             gap: 3px;
//             width: 100%;
//             display: flex;
//             margin-bottom: 2px;
//             align-items: center;
//             justify-content: center;

//             .module-listItem {
//                 flex: 1;
//                 width: 100%;
//                 display: flex;
//                 cursor: pointer;
//                 align-items: center;
//                 justify-content: center;

//                 &:hover {
//                     color: $mainBlue;
//                     background-color: $mainBlueLite2;

//                 }

//                 &.active {
//                     p {
//                         color: $mainColor;
//                         font-size: 11px;
//                         position: absolute;
//                         font-family: AcuminBold;
//                         text-shadow: 1px 1px 1px $black;
//                     }
//                 }

//                 img {
//                     width: 100%;
//                     height: 100%;
//                     object-fit: cover;
//                 }

//                 p {
//                     color: $mainColor;
//                     font-size: 11px;
//                     position: absolute;
//                     font-family: AcuminLight;
//                     text-shadow: 1px 1px 1px $black;
//                 }
//             }

//         }

//         .resLinksWrapper {
//             gap: 3px;
//             display: flex;
//             list-style: none;
//             flex-direction: column;

//             .res-link {
//                 color: inherit;
//                 text-decoration: none;
//             }

//             .women-home-item {
//                 gap: 5px;
//                 display: flex;
//                 color: inherit;
//                 cursor: pointer;
//                 align-items: center;
//                 text-decoration: none;
//                 justify-content: flex-start;
//                 padding: 12px 0px 12px 20px;
//                 background-color: #FFD1DC;

//                 .sideHomeIcon {
//                     color: $deepBlack;
//                     font-size: 18px;
//                 }

//                 span {
//                     color: $deepBlack;
//                     font-size: 12px;
//                     margin-top: 5px;
//                     letter-spacing: 0.4px;
//                     // font-family: AcuminLight;
//                 }
//             }

//             .men-home-item {
//                 gap: 5px;
//                 display: flex;
//                 color: inherit;
//                 cursor: pointer;
//                 align-items: center;
//                 text-decoration: none;
//                 background-color: #89CFF0;
//                 justify-content: flex-start;
//                 padding: 12px 0px 12px 20px;

//                 .sideHomeIcon {
//                     color: $deepBlack;
//                     font-size: 18px;
//                 }

//                 span {
//                     color: $deepBlack;
//                     font-size: 12px;
//                     margin-top: 5px;
//                     letter-spacing: 0.4px;
//                     // font-family: AcuminLight;
//                 }
//             }

//             .kids-home-item {
//                 gap: 5px;
//                 display: flex;
//                 color: inherit;
//                 cursor: pointer;
//                 align-items: center;
//                 text-decoration: none;
//                 background-color: $lime;
//                 justify-content: flex-start;
//                 padding: 12px 0px 12px 20px;

//                 .sideHomeIcon {
//                     color: $deepBlack;
//                     font-size: 18px;
//                 }

//                 span {
//                     color: $deepBlack;
//                     font-size: 12px;
//                     margin-top: 5px;
//                     letter-spacing: 0.4px;
//                     // font-family: AcuminLight;
//                 }
//             }

//             .sideLinks {
//                 display: flex;
//                 color: inherit;
//                 align-items: center;
//                 padding: 10px;
//                 text-decoration: none;
//                 background-color: $cardBack;
//                 justify-content: space-between;

//                 p {
//                     color: $black;
//                     font-size: 13px;
//                     text-transform: uppercase;
//                 }


//                 h4 {
//                     color: $mainRed;
//                     font-size: 12px;
//                     align-self: flex-start;
//                 }

//                 .dropIcon {
//                     font-size: 11px;
//                     cursor: pointer;
//                 }

//                 &:hover {
//                     background-color: lightgray;
//                 }
//             }

//             .options {
//                 gap: 2px;
//                 width: 100%;
//                 display: flex;
//                 flex-direction: column;

//                 .sideLinks2 {
//                     width: 100%;
//                     display: flex;
//                     color: inherit;
//                     align-items: center;
//                     padding: 10px 20px;
//                     cursor: pointer;
//                     text-decoration: none;
//                     background-color: lightgray;
//                     background-color: $cardBack2;
//                     justify-content: space-between;

//                     p {
//                         color: $black;
//                         font-size: 11px;
//                         font-family: AcuminLight;
//                     }

//                 }
//             }


//             .options22 {
//                 display: flex;
//                 flex-direction: column;
//                 background-color: lightPink;
    
//                 .menuItem2 {
//                     color: $moneyGreen;
//                     font-size: 18px;
//                     cursor: pointer;
//                     padding: 15px 30px;
//                     border: 0.2px solid lightgray;
        
//                     &:hover {
//                         color: pink;
//                     }
        
//                     &:focus {
//                         color: pink;
//                     }
    
//                     span{
//                         font-size: 16px;
//                         letter-spacing: 0.5px;
//                         font-family: Futura-Regular;
//                         @include phone{
//                             font-size: 20px;
//                         }
//                     }
    
                    
//                 }
    
//             }

//         }



//         .text {
//             gap: 5px;
//             margin-top: -72px;
//             display: flex;
//             margin-bottom: 10px;
//             align-items: flex-start;
//             justify-content: flex-start;

//             p {
//                 font-size: 11.5px;
//                 font-family: AcuminLight;
//             }
//         }

//     }

    // ::-webkit-scrollbar {
    //     width: 10px;
    // }
    // ::-webkit-scrollbar-track {
    //     background-clip: content-box; 
    //     box-shadow: inset 0 0 3px gray; 
    // }
    // ::-webkit-scrollbar-thumb {
    //     background: #6E6E6E; 
    //     background-color: rgba(110,110,110, 0.2);
    // }
  

}