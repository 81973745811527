@import '../../global.scss';

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(68, 68, 68);
    min-height: 80vh;

    h2 {
        font-size: 55px;
    }

    p {
        font-size: 20px;
    }
}