@import '../../global.scss';


// ------------ RETURN POLICY -----------
.return{
    margin-top: 130px;
    background-color: pink;
    gap: 30px;
    width: 100%;
    display: flex;
    padding: 0px 100px;

    .returnWrapper{
        
    }
}

// ------------ PRIVACY POLICY -----------
.privacy{

}

// ------------ TERMS & CONDITION -----------
.terms{
    display: flex;
    margin-top: 90px;
    padding: 10px 40px;
    background-color: pink;
}

