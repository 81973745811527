@import '../../global.scss';

//FEATURED BRAND
.featuredBrand{
    gap: 10px;
    display: flex;
    padding: 40px 50px;
    margin-top: 10px;
    flex-direction: column;

    @include large{
        padding: 40px 5px;
    }

    .top{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3{
            font-size: 23px;
            color: $black;
            font-family: AcuminMedium;
            text-transform: uppercase;
            @include tablet{
                font-size: 18px;
            }
        }
        .feature-link{
            gap: 5px;
            display: flex;
            color: inherit;
            cursor: pointer;
            align-items: center;
            text-decoration: none;

            span{
                color: $mainBlue;
                font-size: 16px;
                font-family: AcuminLight;
                text-transform: uppercase;
                @include tablet{
                    font-size: 15px;
                }
            }
            .featureIcon{
                font-size: 22px;
                color: $mainBlue;
                margin-top: -6px;
            }
        }
        
    }
    .bottom{
        flex: 1;
        gap: 5px;
        display: flex;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(4, 1fr);

        @include tablet{
            gap: 5px;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

//FEATURED PRODUCT
.featuredProduct{
    gap: 10px;
    display: flex;
    padding: 40px 50px;
    margin-top: 20px;
    margin-bottom: 30px;
    flex-direction: column;

    @include extralarge{
        padding: 40px 15px;
    }
    @include tablet{
        padding: 40px 10px;
    }

    .top{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3{
            font-size: 23px;
            color: $black;
            font-family: AcuminMedium;
            text-transform: uppercase;
            @include tablet{
                font-size: 18px;
            }
        }

        .slide-link{
            gap: 5px;
            display: flex;
            color: inherit;
            align-items: center;
            text-decoration: none;
    
            .slide-Arrow{
                font-size: 22px;
                color: $mainBlue;
                margin-top: -6px;
            }
            span{
                color: $mainBlue;
                font-size: 16px;
                font-family: AcuminLight;
                text-transform: uppercase;
                @include tablet{
                    font-size: 15px;
                }
            }
            
        }
        
    }

    .bottom{
        flex: 1;
        gap: 5px;
        display: flex;
        display: grid;
        justify-content: center;

        @include tablet{
            // gap: 5px;
        }
    }

    .carousel {
        // gap: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .trendingCarouselItems{
        padding: 0px 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include phone{
            // padding: 0px 5px;
        }
    }
       
}