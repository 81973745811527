@import '../../global.scss';

.shippingPage {
    gap: 20px;
    display: flex;
    height: 100vh;
    flex-direction: column;
    background-color: $offwhite;

    .link {
        color: inherit;
        text-decoration: none;
    }

    .shippingHeader {
        top: 0;
        height: 55px;
        display: flex;
        z-index: 999;
        position: sticky;
        padding: 10px 40px;
        align-items: center;
        justify-content: space-between;
        border: 0.5px solid lightgrey;
        background-color: #FFFFFF;

        .checkoutTop {
            flex: 1;
            gap: 20px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            .checkoutLogoWrapper {
                display: flex;

                .checkoutLogo {
                    color: $black;
                    font-size: 35px;
                    letter-spacing: -0.5px;
                    font-family: 'DM Serif Display', serif;
                }
            }

        }

        .topContact {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex-direction: column;

            .topItem {
                gap: 10px;
                display: flex;
                // align-items: center;
                justify-content: flex-end;

                .item {
                    gap: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .topIcon {
                        font-size: 14px;
                        color: $moneyGreen;
                        background-color: $mainColor;
                    }

                    span {
                        color: $subHeading;
                        font-size: 12px;
                        margin-top: 3px;
                        letter-spacing: 0.5px;
                        font-family: AcuminLight;
                    }

                    p {
                        font-size: 12px;
                        color: $moneyGreen;
                        margin-top: 3px;
                        letter-spacing: 0.5px;
                        font-family: AcuminLight;
                    }

                }
            }

            .time {
                color: $subHeading;
                font-size: 12px;
                margin-top: 3px;
                letter-spacing: 0.5px;
                font-family: AcuminLight;
            }


        }
    }

    .chekoutShippingWrapper {
        // top: 60px;
        gap: 70px;
        width: 100%;
        display: flex;
        // margin-top: 60px;
        padding: 0px 40px;
        // height: calc(100vh - 70px);

        .shippingLeft {
            gap: 20px;
            flex: 1.5;
            display: flex;
            flex-direction: column;

            .leftItem {
                gap: 20px;
                width: 100%;
                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 18px;
                    color: $black;
                    font-family: AcuminMedium;
                }

                .deliveryMethod {
                    gap: 10px;
                    width: 100%;
                    display: flex;

                }
            }

            .detailsWrapper {
                // background-color: pink;
                // border: 0.3px solid lightgray;
            }
        }

        .shippingRight {
            flex: 1;
            gap: 10px;

            position: relative;

            padding: 10px 20px;
            display: flex;
            height: calc(100vh - 90px);
            flex-direction: column;
            border-radius: 10px;
            border: 0.4px solid lightgray;
            background-color: $mainColor;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                h3 {
                    font-size: 16px;
                    color: $black;
                    font-family: AcuminLight;
                }
                h4 {
                    font-size: 16px;
                    color: $black;
                    font-family: AcuminLight;
                }
    
            }

            hr {
                width: 100%;
                display: flex;
                margin-top: 2px;
                margin-bottom: 2px;
                border: 0.4px solid lightgray;
            }

            .productsWrapper{
                gap: 3px;
                width: 100%;
                height: 370px;
                overflow: scroll;
                padding-right: 10px;
                // margin-bottom: 10px;
                
            .productDetail {
                gap: 8px;
                width: 100%;
                display: flex;
                padding: 5px;
                // border: 0.5px solid lightgray;

                @include mobile {
                    padding: 3px;
                }

                img {
                    width: 60px;
                    height: 80px;
                }

                .details {
                    gap: 3px;
                    width: 100%;
                    display: flex;
                    color: $subHeading;
                    flex-direction: column;

                    .productBrand {
                        font-size: 13px;
                        font-family: AcuminLight;
                        text-transform: uppercase;
                    }

                    .productName {
                        font-size: 12.5px;
                        font-family: AcuminLight;
                    }

                    .detailsItem {
                        gap: 5px;
                        display: flex;
                        align-items: center;

                        p {
                            font-size: 12.5px;
                            font-family: AcuminLight;
                        }
                    }

                }

                .priceWrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .priceItem1 {
                        // flex: 1;
                        gap: 5px;
                        width: 100%;
                        display: flex;

                        span {
                            font-size: 12px;
                            font-family: Acuminmedium;
                        }

                        .discountItem {
                            gap: 3px;
                            width: 100%;
                            display: flex;
                            color: $black;
                            align-items: center;

                            p {
                                font-weight: 500;
                                font-size: 12px;
                            }

                            span {
                                font-size: 13px;
                                color: $black;
                            }
                        }
                    }

                    .priceItem2 {
                        // flex: 1;
                        gap: 5px;
                        width: 100%;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;

                        span {
                            font-size: 12px;
                            font-family: Acuminmedium;
                        }

                        .discountItem {
                            gap: 3px;
                            width: 100%;
                            display: flex;
                            color: $black;
                            align-items: center;

                            p {
                                font-weight: 500;
                                font-size: 12px;
                            }

                            span {
                                font-size: 13px;
                                color: $black;
                            }
                        }
                    }
                }

            }

            hr {
                width: 100%;
                display: flex;
                margin-top: 2px;
                margin-bottom: 2px;
                border: 0.4px solid lightgray;
            }
        }

            .totalWrapper {
                gap: 6px;
                bottom: 0;
                display: flex;
                // margin-bottom: 0px;
                flex-direction: column;
            
                .totalItem{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-size: 14px;
                        font-family: AcuminMedium;
                    }
                    h4{
                        font-size: 14px;
                        font-family: AcuminMedium;
                    }

                    hr {
                        width: 100%;
                        // margin-top: 2px;
                        // margin-bottom: 2px;
                        border: 0.4px solid lightgray;
                    }
                }
                .totalItem2{
                    width: 100%;
                    display: flex;
                    color: $subHeading;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-size: 15px;
                        font-family: AcuminBold;
                    }
                    h4{
                        font-size: 15px;
                        font-family: AcuminBold;
                    }

                    hr {
                        width: 100%;
                        // margin-top: 2px;
                        // margin-bottom: 2px;
                        border: 0.4px solid lightgray;
                    }
                }
            }



        }

    }

}