@import '../../global.scss';

// ----------- DASHBOARD -----------
.dashboard{
    gap: 20px;
    width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;

    .topDashWrapper{
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .dashItem1{
            flex: 1;
            gap: 20px;
            display: flex;
            border-radius: 5px;
            padding: 15px 10px;
            flex-direction: column;
            background-color: $mainColor;
            border: 0.4px solid lightgray;
            border-bottom: 3px solid $moneyGreen;

            h2{
                font-size: 16px;
                font-family: AcuminLight;
            }
            .dashItemWrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .amount {
                    gap: 5px;
                    display: flex;
                    align-items: center;

                    h2 {
                        font-size: 17px;
                        font-family: AcuminMedium;
                    }

                    span {
                        font-size: 12px;
                        letter-spacing: 0.6px;
                        font-family: AcuminLight;
                    }

                }
                .dashIcon1{
                    font-size: 20px;
                    color: $moneyGreen;
                }
            }


        }

        .dashItem2{
            flex: 1;
            gap: 20px;
            display: flex;
            border-radius: 5px;
            padding: 15px 10px;
            flex-direction: column;
            background-color: $mainColor;
            border: 0.4px solid lightgray;
            border-bottom: 3px solid #E89611;

            h2{
                font-size: 16px;
                font-family: AcuminLight;
            }
            .dashItemWrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .amount {
                    gap: 5px;
                    display: flex;
                    align-items: center;

                    h2 {
                        font-size: 17px;
                        font-family: AcuminMedium;
                    }

                    span {
                        font-size: 12px;
                        letter-spacing: 0.6px;
                        font-family: AcuminLight;
                    }

                }
                .dashIcon2{
                    font-size: 20px;
                    color: #E89611;
                }
            }


        }

        .dashItem3{
            flex: 1;
            gap: 20px;
            display: flex;
            border-radius: 5px;
            padding: 15px 10px;
            flex-direction: column;
            background-color: $mainColor;
            border: 0.4px solid lightgray;
            border-bottom: 3px solid #8B008B;

            h2{
                font-size: 16px;
                font-family: AcuminLight;
            }
            .dashItemWrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    font-size: 17px;
                    font-family: AcuminMedium;
                }
                .dashIcon3{
                    font-size: 20px;
                    color: #8B008B;
                }
            }


        }

        .dashItem4{
            flex: 1;
            gap: 20px;
            display: flex;
            border-radius: 5px;
            padding: 15px 10px;
            flex-direction: column;
            background-color: $mainColor;
            border: 0.4px solid lightgray;
            border-bottom: 3px solid #0F52BA;

            h2{
                font-size: 16px;
                font-family: AcuminLight;
            }
            .dashItemWrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    font-size: 17px;
                    font-family: AcuminMedium;
                }
                .dashIcon4{
                    font-size: 20px;
                    color: #0F52BA;
                }
            }


        }

    }

    .bottomDashWrapper{
        gap: 10px;
        width: 100%;
        display: flex;

    }
    
}

// ----------- USERS -----------
.adminUsers{

}

// ----------- PRODUCTS -----------
// .adminProducts{
//     gap: 20px;
//     width: 100%;
//     display: flex;
//     padding: 20px;
//     flex-direction: column;

//     background-color: pink;

    .productsContainer {
        // flex: 6;
        gap: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .pdtDatatable {
            height: 550px;
            padding: 10px;
        
            .pdtTableTitle {
                width: 100%;
                font-size: 18px;
                color: rgba(106, 13, 173, 0.8);
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                .link {
                    padding: 5px 15px;
                    color: green;
                    font-size: 16px;
                    font-weight: 400;
                    border-radius: 5px;
                    background-color: rgba(0, 128, 0, 0.105);
                    border: 1px solid rgba(0, 128, 0, 0.388);
                }
            }
        
            .cellWidthImg {
                gap: 5px;
                display: flex;
                align-items: center;
        
                .cellImg {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    object-fit: cover;
                    // margin-right: 20px;
                }
            }

            .buyingPrice{
                font-weight: 500;
                color: purple;
            }
            .sellingPrice{
                font-weight: 500;
                color: $moneyGreen;
            }
            .discountPrice{
                font-weight: 500;
                color: $Sale;
            }
            .profit{
                font-weight: 500;
                color: $mainBlue;
            }
        
            .cellName {
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;
            }
        
            .cellWithStatus {
                padding: 5px;
                border-radius: 5px;
        
                &.active {
                    background-color: rgba(0, 128, 0, 0.05);
                    color: green;
                }
        
                &.pending {
                    background-color: rgba(255, 217, 0, 0.05);
                    color: goldenrod;
                }
        
                &.passive {
                    background-color: rgba(220, 0, 0, 0.05);
                    color: $mainRed;
                }
            }
        
            .status {
                padding: 5px;
                border-radius: 5px;
        
                &.Delivered {
                    color: green;
                    background-color: rgb(0, 128, 0, 0.151);
                }
        
                &.Pending {
                    color: goldenrod;
                    background-color: rgb(189, 189, 3, 0.151);
                }
        
            }
        
            .paymentMethod {
                border-radius: 5px;
        
                &.Cash {
                    padding: 8px 36px;
                    color: green;
                }
        
                &.MobileMoney {
                    padding: 8px 10px;
                    color: goldenrod;
                }
        
                &.Online {
                    padding: 8px 32px;
                    color: rgb(10, 10, 137);
                }
        
            }
        
            .cellAction {
                display: flex;
                align-items: center;
                gap: 15px;
        
                .viewButton {
                    padding: 2px 5px;
                    border-radius: 5px;
                    border: 1px dotted rgba(0, 100, 0, 0.682);
                    cursor: pointer;
                }
        
                .deleteButton {
                    padding: 2px 5px;
                    border-radius: 5px;
                    color: $mainRed;
                    border: 1px dotted rgba(220, 20, 60, 0.6);
                    cursor: pointer;
                }
            }
        
        }
    }

// }

// ----------- ORDERS -----------
.adminOrders{

}

// ----------- WISH LIST -----------
.adminWish{

}