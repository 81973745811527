@import '../../global.scss';

.loader{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(12, 4, 4, .8),
}

.spinnerWrapper{
    gap: 5px;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column;
    background-color: $dullBgrd;

    .spinner{
        width: 100%;
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}