@import '../../../global.scss';

//SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;

    .category {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .catWrapper {
            width: 100%;
            display: flex;
            padding: 0px 0px 10px;
            flex-direction: column;
            margin-bottom: 10px;
            border-bottom: 0.4px solid gray;

            .catItem {
                gap: 15px;
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                justify-content: space-between;

                span {
                    color: $gray;
                    font-size: 11px;
                    font-weight: 300;
                    letter-spacing: 0.4px;
                }

                .dropIcon {
                    color: gray;
                    font-size: 20px;
                    margin-top: -3px;
                    cursor: pointer;
                }
            }

            .optionItems {

                .filterItem {
                    display: block;
                    // display: flex;
                    position: relative;
                    padding-left: 22px;
                    // padding-left: 0px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    align-items: center;
                    justify-content: center;


                    input {
                        opacity: 0;
                        cursor: pointer;
                        position: absolute;
                    }

                    p {
                        top: 3;
                        font-size: 13px;
                        // margin-top: 3px;
                        font-family: AcuminLight;
                    }

                    .checkmark {
                        top: 0;
                        left: 0;
                        height: 14px;
                        width: 14px;
                        border-radius: 50%;
                        position: absolute;
                        background-color: #eee;

                        margin-top: -1px;

                    }

                }

                .all {
                    background: linear-gradient($mainBlue, crimson);
                }

                .filterItem:hover input~.checkmark {
                    background-color: #ccc;
                }

                .filterItem input:checked~.checkmark {
                    background-color: $mainBlue;
                }

                .checkmark:after {
                    content: "";
                    display: none;
                    position: absolute;
                }

                .filterItem input:checked~.checkmark:after {
                    display: block;
                    // display: flex;
                }

                .filterItem .checkmark:after {
                    // top: 6.4px;
                    // left: 6.4px;
                    top: 3.5px;
                    left: 3.4px;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background: white;
                }

                .line {
                    margin-top: 3rem;
                    border-color: #f7f7f7;
                }

                .read-more-link {
                    color: $mainBlue;
                    cursor: pointer;
                    font-size: 12.5px;
                    font-weight: 300;
                    display: flex;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    align-items: center;
                    letter-spacing: 0.5px;
                    letter-spacing: 0.4px;
                    text-decoration: underline;

                    p {
                        color: $mainBlue;
                        font-weight: 300;
                        margin-bottom: 2px;

                    }
                }

            }

            .optionsWrapper{
                gap: 20px;
                width: 100%;
                display: flex;

                .options {

                    .filterItem {
                        display: block;
                        position: relative;
                        padding-left: 22px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        align-items: center;
                        justify-content: center;
    
                        input {
                            opacity: 0;
                            cursor: pointer;
                            position: absolute;
                        }
    
                        p {
                            top: 3;
                            font-size: 12.5px;
                            font-family: AcuminLight;
                        }
    
                        .checkmark {
                            top: 0;
                            left: 0;
                            height: 14px;
                            width: 14px;
                            border-radius: 50%;
                            position: absolute;
                            background-color: #eee;
    
                            margin-top: -1px;
    
                        }
    
                    }
    
                    .all {
                        background: linear-gradient($mainBlue, crimson);
                    }
    
                    .filterItem:hover input~.checkmark {
                        background-color: #ccc;
                    }
    
                    .filterItem input:checked~.checkmark {
                        background-color: $mainBlue;
                    }
    
                    .checkmark:after {
                        content: "";
                        display: none;
                        position: absolute;
                    }
    
                    .filterItem input:checked~.checkmark:after {
                        display: block;
                    }
    
                    .filterItem .checkmark:after {
                        top: 3.5px;
                        left: 3.4px;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background: white;
                    }
    
                    .line {
                        margin-top: 3rem;
                        border-color: #f7f7f7;
                    }
    
                    .read-more-link {
                        color: $mainBlue;
                        cursor: pointer;
                        font-size: 12.5px;
                        font-weight: 300;
                        display: flex;
                        margin-top: 10px;
                        margin-bottom: 5px;
                        align-items: center;
                        letter-spacing: 0.5px;
                        letter-spacing: 0.4px;
                        text-decoration: underline;
    
                        p {
                            color: $mainBlue;
                            font-weight: 300;
                            margin-bottom: 2px;
    
                        }
                    }
    
                }
            }

        }


    }

}

//SUB CATEGORIES
.subCatsWrapper {
    gap: 5px;
    display: flex;
    flex-direction: column;

    .btnWrapper {
        gap: 10px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 13px;
        @include tablet{
            gap: 8px;
            margin-top: 5px;
        }

        .btns {
            color: $black;
            border: none;
            font-size: 11px;
            padding: 6px 10px;
            cursor: pointer;
            letter-spacing: 0.5px;
            font-family: AcuminLight;
            outline: 1px solid lightgray;

            &:focus {
                color: $black;
                font-family: AcuminMedium;
                background-color: $mainColor;
                outline: 0.4px solid $black;
            }
            @include phone{
                font-size: 12px;
            }
        }

    }

}

.title {
    // margin-left: 20rem;
    // margin-bottom: 20px;
    // margin-top: 20px;
    // font-size: 20px;
}