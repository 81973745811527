@import '../../global.scss';

.footer {
    width: 100%;
    bottom: 0;
    display: flex;
    // margin-top: 50px;
    align-items: center;
    flex-direction: column;
    background-color: lightgray;

    .paymentOptions {
        gap: 20px;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $deepBlack;


        .options {
            gap: 30px;
            display: flex;

            @include tablet {
                gap: 10px;
            }

            .paymentIcon {
                width: 40px;
                height: 25px;
            }

            .airtelIcon {
                width: 60px;
                height: 25px;
            }

            .socialIcon {
                width: 40px;
                height: 25px;
                color: #E5E4E2
            }
        }

        .socialOptions {
            gap: 20px;
            display: flex;

            @include tablet {
                gap: 0px;
            }

            .socialIcon {
                width: 40px;
                height: 25px;
                color: #E5E4E2
            }
        }

        .separator {

            span {
                margin-left: 5px;
                color: #FFFFFF;
            }
        }
    }

    .hrWrapper {
        width: 100%;
        display: flex;

        hr {
            width: 100%;
            border: 0.7px solid gray;
        }
    }

    .linksWrapper {
        gap: 20px;
        width: 100%;
        display: grid;
        padding: 0px 100px;
        margin-top: 30px;
        align-items: center;
        justify-content: center;
        grid-template-columns: repeat(4, 1fr);

        @include large {
            gap: 10px;
            padding: 0px 20px;
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        .left,
        .center,
        .right,
        .farRight {
            flex: 1;
            gap: 10px;
            width: 100%;
            display: flex;
            margin-bottom: 20px;
            align-items: flex-start;
            flex-direction: column;

            h3 {
                font-size: 13px;
            }

            span {
                font-size: 13.5px;
                cursor: pointer;
                font-family: AcuminLight;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }

    .bottomWrapper {
        width: 100%;
        height: 45px;
        display: flex;
        // padding: 50px 0px;
        margin-bottom: 20px;
        // margin-top: 20px;
        flex-direction: column;

        .hrWrapper {
            width: 100%;
            display: flex;
            padding: 10px 0px;

            // @include tablet {
            //     padding: 0px 0px;
            // }

            hr {
                width: 100%;
                border: 0.3px solid $hr;
            }
        }

        .text {
            gap: 5px;
            display: flex;
            // padding: 20px 0px;
            align-items: center;
            justify-content: center;

            p {
                font-size: 13px;
                font-family: AcuminLight;
            }
        }
    }
}