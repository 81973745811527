@import '../global.scss';

.cart {
    width: 100%;
    margin-top: 103px;
    @include large{
        margin-top: 90px;
    }

    .cartHr {
        width: 100%;
        display: flex;
        margin-top: 5px;
        border: 0.4px solid lightgray;

        @include large{
            display: none;
        }
    }

    .toast-message {
        background: darkblue;
        color: #fff;
        font-size: 20px;
        width: 34vw;
        padding: 30px 20px;
    }

    .cartCover{
        padding: 20px 30px;
        width: 100%;

        @include extralarge{
            padding: 20px 30px;
        }
        @include mobile{
            padding: 20px 10px;
        }

    }

    h1 {
        font-size: 22px;
        display: flex;
        font-weight: 300;
        align-items: center;
        margin-bottom: 10px;

        @include large{
            justify-content: center;
        }
    }

    .emptyWrapper{
        display: flex;
        margin-top: 40px;
        height: calc(100vh - 540px);

        .emptyText{
            gap: 10px;
            display: flex;
            flex-direction: column;

            h3{
                font-size: 17px;
                color: $subHeading;
                letter-spacing: 0.5px;
                font-family: AcuminLight;
            }
            .emptylink{
                color: inherit;
                text-decoration: none;
            }
            .shopping{
                gap: 10px;
                display: flex;
                cursor: pointer;
                align-items: center;

                p{
                    font-size: 15px;
                    color: $mainBlue;
                    letter-spacing: 0.5px;
                    font-family: AcuminLight; 
                }
                .emptyIcon{
                    color: $mainBlue;
                    margin-top: -3px;
                }
            }
        }
    }

    .bottom {
        gap: 20px;
        display: flex;
        justify-content: space-between;

        @include large{
            gap: 0px;
            flex-direction: column;
        }

        .info {
            flex: 3;

            .productHeading {
                display: flex;
                font-size: 15px;
                font-weight: 300;
                justify-content: space-between;
                background-color: #ebecf0;

                @include tablet{
                    display: none;
                }

                .productTitle {
                    flex: 2;
                    display: flex;
                    padding: 10px;
                    font-size: 14px;
                    padding: 20px 10px;
                    border: 0.5px solid lightgray;
                }

                .productEdit {
                    flex: 1;
                    display: flex;
                    font-size: 14px;
                    align-items: center;
                    justify-content: center;
                    border: 0.5px solid lightgray;
                }

                .productPrice {
                    flex: 1;
                    display: flex;
                    font-size: 14px;
                    align-items: center;
                    justify-content: center;
                    border: 0.5px solid lightgray;
                }
            }

            .productItem {
                display: flex;
                flex-direction: column;

                .product {
                    display: flex;
                    justify-content: space-between;

                    .productDetail {
                        flex: 2;
                        display: flex;
                        padding: 10px;
                        border: 0.5px solid lightgray;

                        @include mobile{
                            padding: 3px;
                        }

                        img {
                            width: 100px;
                            height: 120px;

                            @include mobile{
                                width: 100px;  
                                height: 120px;
                            }
                        }

                        .details {
                            display: flex;
                            padding: 10px 5px 0px 10px;
                            flex-direction: column;
                            justify-content: space-between;

                           .detailsTop{
                            gap: 15px;
                            display: flex;
                            flex-direction: column;

                            .productName {
                                font-size: 12px;
                                font-family: AcuminLight;
                                text-transform: uppercase;
                                @include mobile{
                                    font-size: 12px;
                                }
                            }

                            .pdtDetailsItem1 {
                                gap: 3px;
                                display: flex;
                                flex-direction: column;
                                p{
                                    font-size: 12px;
                                    font-family: AcuminLight;
                                    text-transform: uppercase;
                                }
                            }
                            .pdtDetailsItem2 {
                                display: flex;
                                margin-top: -10px;
                                align-items: center;
                                p{
                                    font-size: 13px;
                                    margin-top: 3px;
                                    font-family: AcuminLight;
                                }

                                .divider{
                                    width: 2px;
                                    margin: 0 10px;
                                    height: 60%;
                                    // margin-top: 20px;
                                    display: inline-block;
                                    background-color: $lighterGray;
                                }
                            }
                           }
                            .pdtDelete {
                                gap: 10px;
                                border: none;
                                color: crimson;
                                display: flex;
                                cursor: pointer;
                                align-items: center;
                                p{
                                    font-size: 13px;
                                    margin-top: 5px;
                                    font-family: AcuminLight;
                                }
                                .cartDelIcon{
                                    font-size: 14px;
                                }
                            }

                        }
                    }

                    .qttyDetails {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        border: 0.5px solid lightgray;

                        .qttyContainer {
                            gap: 15px;
                            display: flex;
                            margin-top: 20px;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .qttyPrice{
                                display: flex;

                                .qttyWrapper1{
                                    gap: 6px;
                                    display: flex;
                                    color: crimson;
                                    align-items: center;
    
                                    p{
                                        font-size: 13px;
                                        font-family: AcuminLight;
                                    }
    
                                    .pdtPrice {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 16px;
                                        font-family: AcuminLight;
    
                                    }
    
                                }
                                .qttyWrapper2{
                                    gap: 6px;
                                    display: flex;
                                    align-items: center;
    
                                    p{
                                        font-size: 14px;
                                        margin-top: 1px;
                                        font-family: AcuminLight;
                                    }
    
                                    .pdtPrice {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 16px;
                                        font-family: AcuminLight;
    
                                    }
    
                                }
                            }

                            .pdtQtty {
                                gap: 10px;
                                display: flex;
                                font-size: 13px;
                                font-weight: 300;
                                align-items: center;
                                justify-content: center;
                            }

                            .quantity {
                                // gap: 10px;
                                display: flex;
                                align-items: center;
                                
                                span {
                                    width: 35px;
                                    height: 35px;
                                    display: flex;
                                    font-size: 12px;
                                    align-items: center;
                                    // border-radius: 3px;
                                    justify-content: center;
                                    border: 0.4px solid lightgray;
                                }
            
                                button {
                                    width: 35px;
                                    height: 35px;
                                    display: flex;
                                    border: none;
                                    padding: 5px 5px;
                                    cursor: pointer;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: $dullBgrd;
                                    border: 0.4px solid lightgray;
            
                                    .qttyIcon{
                                        font-size: 20px;
                                        color: $mainBlue;
                                        color: $black;
                                        background-color: $dullBgrd;
                                    }
                                }
                            }

                        }
                    }

                    .priceDetail {
                        flex: 1;
                        gap: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0.5px solid lightgray;

                        @include tablet{
                            display: none;
                        }

                        p{
                            font-size: 14px;
                            margin-top: 1px;
                            font-family: AcuminLight;
                        }

                        h4 {
                            font-size: 16px;
                            font-family: AcuminLight;
                        }
                    }
                }

                .mobileTotal{
                    width: 100%;
                    display: none;
                    margin-top: 5px;
                    padding: 15px 3px;
                    margin-bottom: 20px;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 0.5px solid lightgray;
                    border-bottom: 0.5px solid lightgray;

                    @include tablet{
                        display: flex;
                    }

                    h2{
                        font-size: 15px;
                        font-Weight: 400;
                    }
                }
            }

        }

        .summary {
            flex: 1.2;
            height: 459px;
            padding: 20px;
            border: 0.5px solid lightgray;

            @include large{
                width: 50%;
                align-self: flex-end;
            }
            @include tablet{
                width: 70%;
                align-self: flex-end;
            }


            .summaryTitle {
                font-size: 17px;
                text-align: center;
                font-family: AcuminLight;
            }

            .summaryItem {
                margin: 30px 0px;
                display: flex;
                color: $subHeading;
                align-items: flex-end;
                justify-content: space-between;

                @include large{
                    margin: 30px 0px 10px;
                }
    

                span {
                    font-size: 16px;
                    font-family: AcuminBold;
                    
                }

                .summaryItemWrapper{
                    gap: 6px;
                    display: flex;
                    align-items: center;
                    h4 {
                        font-size: 18px;
                        font-family: AcuminMedium;
                    }
                    p {
                        font-size: 16px;
                        font-family: AcuminMedium;
                    }
                }
            }

            .summaryBtn {
                padding: 15px;
                width: 100%;
                font-size: 14px;
                font-weight: 300;
                margin-top: 10px;
                cursor: pointer;
                border-radius: 3px;
                border: none;
                color: white;
                background-color: $green;
            }

            .paymentIcons {
                display: flex;
                gap: 12px;
                margin-top: 30px;
                flex-direction: column;

                @include large{
                    gap: 8px;
                    margin-top: 15px;
                }

                .paymentTitle {
                    font-size: 14px;
                    font-weight: 300;
                }

                .paymentDiv {
                    display: flex;
                    gap: 20px;
                    // margin-bottom: 10px;

                    .image1 {
                        width: 38px;
                        height: 28px;
                    }

                    .image2 {
                        width: 45px;
                        height: 25px;
                    }
                }

            }
        }


    }

    .cartFooter{
        margin-top: 50px;

        h3{
            color: gray;
            font-size: 19px;
            display: flex;
            font-weight: 300;
            color: $subHeading;
            align-items: center;
            margin-bottom: 10px;
            font-family: AcuminLight;

        }

        .cartFooterWrapper{
            gap: 10px;
            display: flex;
            margin-bottom: 50px;
            flex-direction: column;

            .item{
                gap: 10px;
                color: gray;
                display: flex;
                align-items: center;

                span{
                    gap: 5px;
                    display: flex;
                    font-size: 13px;
                    font-family: AcuminLight;
                }
            
                .itemIcon{
                    font-size: 16px;
                }

            }
        }
    }
}





///model cart
.cartCart {
    top: 100px;
    right: 20px;
    width: 300px;
    padding: 20px 20px 0px;
    z-index: 999;
    display: flex;
    color: $black;
    position: absolute;
    border-radius: 5px;
    flex-direction: column;
    background-color: white;
    border: 0.4px solid gray;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

    h2 {
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .cartItem {
        gap: 20px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }

        .details {
            h3 {
                font-size: 15px;
                margin-bottom: 5px;
            }

            p {
                font-size: 13px;
                font-weight: 300;
                color: gray;
                margin-bottom: 5px;
            }

            .price {
                font-size: 13px;
                font-weight: 300;
                color: $green;
            }
        }
    }

    .total {
        display: flex;
        justify-content: space-between;
    }

    .reset {
        color: crimson;
        padding: 5px 10px;
        display: flex;
        cursor: pointer;
        border: none;
        font-weight: 300;
        align-items: center;
        justify-content: center;
        background-color: white;
    }

    .view {
        // width: 250px;
        color: white;
        padding: 8px 10px;
        display: flex;
        cursor: pointer;
        border: none;
        margin-top: 14px;
        font-weight: 300;
        letter-spacing: 1px;
        align-items: center;
        justify-content: center;
        background-color: $green;
    }

}