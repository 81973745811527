@import '../../../global.scss';

.edit{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h3{
        font-size: 17px;
        color: $mainBlue;
        margin-top: 10px;
        font-family: AcuminLight;
    }

    .editProfileWrapper{
        gap: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    
        .editBottom{
            gap: 10px;
            display: flex;
            margin-top: 20px;
            flex-direction: column;
    
            .editBottomItem{
                gap: 5px;
                display: flex;
                flex-direction: column;
                
                label {
                    font-size: 14px;
                    color: $subHeading;
                    font-family: AcuminLight;
                }
        
                input{
                    padding: 8px;
                    width: 315px;
                    color: $black;
                    border: 0.5px solid lightgrey;
                }
                input::placeholder {
                    font-size: 14px;
                    // font-family: AcuminLight;

                    @include phone {
                        font-size: 15px;
                        color: gray;
                    }
                }

                input:focus {
                    outline: none;
                    background-color: white;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-text-fill-color: $black;
                    transition: background-color 5000s ease-in-out 0s;
                }
                
            }
    
            .updateBtn{
                width: 315px;
                padding: 8px;
                display: flex;
                color: white;
                border: none;
                margin-top: 10px;
                font-size: 15px;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                background-color: $black2;
    
            }
        }
    
        
    }
}