@import '../global.scss';

.response {
    width: 100%;
    display: flex;
    padding: 50px 0px;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px);
    background-color: $pageBackground;
    // background-image: url("../assets/paid.png");
    // //   background-image: linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)) ,url("../assets/paid.png");
    //   background-size: cover;

    .link {
        color: inherit;
        text-decoration: none;
    }

    .errorWrapper{
        gap: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span{
            font-size: 19px;
            color: red;
            font-family: AcuminLight;
        }
        .resBtn{
            gap: 10px;
            display: flex;
            width: 240px;
            padding: 15px;
            color: $mainColor;
            font-size: 15px;
            cursor: pointer;
            letter-spacing: 0.6px;
            align-items: center;
            justify-content: center;
            font-family: AcuminLight;
            background-color: $black;

            .resArrow{
                font-size: 20px;
            }
        }
    }

    .responseData {
        gap: 20px;
        left: auto;
        right: auto;
        bottom: auto;
        width: 400px;
        display: flex;
        margin-top: 50px;
        padding: 20px 0px;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        background-color: $mainColor;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

        .responseStats {
            gap: 5px;
            display: flex;
            align-items: center;
            flex-direction: column;


            .icon {
                color: $moneyGreen;
                font-size: 25px;
            }

            h2 {
                font-size: 25px;
                color: $moneyGreen;
            }

            h5 {
                font-size: 14px;
                color: $gray;
            }
            span {
                font-size: 14px;
                color: $gray;
            }
        }


        hr {
            display: flex;
            width: 90%;
            border: 0.3px solid lightgray;
        }

        .bottomResponse {
            width: 100%;
            margin-top: -10px;
            padding: 0px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left,
            .right {
                gap: 2px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                p {
                    font-size: 13px;
                    color: $black;
                }

                h4 {
                    color: $gray2;
                }
            }

        }

        .accountBtn {
            gap: 10px;
            width: 190px;
            display: flex;
            padding: 10px;
            font-size: 14px;
            cursor: pointer;
            margin-top: 25px;
            border-radius: 3px;
            align-items: center;
            color: $lightBlue;
            justify-content: center;
            border: 0.5px solid $lightBlue;

            .resArrow{
                font-size: 20px;
            }
        }
    }
}