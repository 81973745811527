@import '../../../global.scss';

.brandPdtWrapper {
    gap: 10px;
    width: 100%;
    padding: 10px 0px;
    display: grid;
    margin-bottom: 50px;
    grid-template-columns: repeat(4, 1fr);

    @include large {
        grid-template-columns: repeat(3, 1fr);
    }

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
    }

}

.brandItem {
    width: 100%;
    margin-bottom: 10px;
    border: 0.3px solid lightgray;

    .brandImage {
        width: 100%;
        height: 100%;
        height: 360px;
        object-fit: cover;

        // @include mobile{
        //     height: 300px;
        // }
        // @include mobile{
        //     height: 180px;
        // }

        @include large{
            height: 300px;
        }
        @include tablet{
            height: 330px;
        }
        @include mobile{
            // height: 290px;
            height: 260px;
        }
    }

    .detailsWrapper {
        display: block;
        width: 270px;
        padding: 0px 5px;
        align-items: flex-start;
        flex-direction: column;

        @include tablet{
            width: 220px;
        }
        @include phone{
            width: 200px;
            width: 180px;
            // background-color: yellow;
        }

        .titles {
            gap: 5px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 3px;

            h3 {
                font-size: 9px;
                text-align: center;
                letter-spacing: 0.5px;
                margin-top: 3px;
                text-transform: uppercase;
            }

            span {
                width: 250px;
                padding: 0;
                color: $gray;
                font-size: 10.5px;
                overflow: hidden;
                position: relative;
                display: inline-block;
                text-align: center;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-transform: uppercase;
                font-family: AcuminLight;
                text-align: left;

                @include tablet{
                    width: 200px;
                }

                @include tablet{
                    width: 200px;
                }
                @include phone{
                    width: 175px;
                    font-size: 10px;
                }
                
            }
            
        }

        .discountWrapper{
            gap: 8px;
            display: flex;

            .discountItem1{
                gap: 3px;
                display: flex;
                color: $black;
                align-items: center;
                position: relative;
                p{
                    font-size: 13px;
                    font-family: AcuminLight;

                }
                span{
                    font-size: 14px;
                    color: $black;
                    font-family: AcuminLight;

                }
            }
            .discountItem1:after {
                position: absolute;
                left: 0;
                top: 43%;
                height: 1px;
                background: $subHeading;
                content: "";
                width: 100%;
                display: block;
            }
            .discountItem2{
                gap: 5px;
                display: flex;
                color: $mainRed;
                align-items: center;
                p{
                    font-size: 15px;
                }
                span{
                    font-size: 17px;
                }
            }

        }

        .priceWrapper {
            gap: 5px;
            display: flex;
            align-items: center;

            .price1 {
                color: $black;
                font-size: 14px;
            }
            .price3 {
                color: $black;
                font-size: 16px;
            }
        }

    }


    // .textWrapper {
    //     gap: 5px;
    //     display: flex;
    //     margin-left: 10px;
    //     margin-right: 10px;
    //     margin-bottom: 5px;
    //     flex-direction: column;

    //     span {
    //         font-size: 12.5px;
    //         letter-spacing: 0.5px;
    //         overflow: hidden;
    //         color: $gray;
    //         text-overflow: ellipsis;
    //         white-space: nowrap;
    //         font-family: AcuminLight;

    //     }

    //     .discountWrapper {
    //         gap: 8px;
    //         display: flex;

    //         .discountItem1 {
    //             gap: 3px;
    //             display: flex;
    //             color: $black;
    //             align-items: center;
    //             position: relative;

    //             p {
    //                 font-size: 12px;
    //                 font-family: AcuminLight;

    //             }

    //             span {
    //                 font-size: 13px;
    //                 color: $black;
    //                 font-family: AcuminLight;

    //             }
    //         }

    //         .discountItem1:after {
    //             position: absolute;
    //             left: 0;
    //             top: 43%;
    //             height: 1px;
    //             background: $subHeading;
    //             content: "";
    //             width: 100%;
    //             display: block;
    //         }

    //         .discountItem2 {
    //             gap: 5px;
    //             display: flex;
    //             color: $mainRed;
    //             align-items: center;

    //             p {
    //                 color: $mainRed;
    //                 font-size: 14px;
    //                 font-family: AcuminMedium;
    //             }

    //             span {
    //                 color: $mainRed;
    //                 font-size: 16px;
    //                 font-family: AcuminMedium;
    //             }
    //         }

    //     }

    //     .priceWrapper {
    //         gap: 5px;
    //         display: flex;
    //         align-items: center;

    //         .price1 {
    //             color: $black;
    //             font-size: 14px;
    //             font-family: AcuminMedium;
    //         }

    //         .price3 {
    //             color: $black;
    //             font-size: 16px;
    //             font-family: AcuminMedium;
    //         }
    //     }

    // }

}