@import '../../global.scss';

.single {
    gap: 5px;
    width: 100%;
    display: flex;
    margin-top: 150px; 
    flex-direction: column;

    @include large{
        margin-top: 95px;
    }


    .top {
        display: flex;
        padding: 0px 0px 0px 170px;

        @include large2 {
            align-items: center;
            padding: 0px 0px 0px 0px;
            justify-content: center;
        }

        span {
            color: gray;
            font-size: 11px;
            font-family: AcuminLight;
        }
    }

    .bottom {
        gap: 40px;
        width: 100%;
        display: flex;
        padding: 0px 0px 50px 170px;

        @include extralarge {
            padding: 0px 20px 50px;
        }

        @include large2 {
            flex-direction: column;
        }

        @include mobile {
            padding: 0px 10px 50px;
        }


        .left {
            flex: 1;
            gap: 10px;
            display: flex;

            @include large {
                flex-wrap: 2;
            }

            .images {
                flex: 0.6;
                width: 100%;
                display: flex;
                flex-direction: column;

                .imgWrapper {
                    // flex: 1;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .leftImg {
                        width: 100%;
                        cursor: pointer;
                        object-fit: cover;
                        margin-bottom: 10px;
                    }
                }
            }

            .mainImage {
                flex: 4;
                width: 100%;

                .imageWrapper {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .rightImg {
                        flex: 5;
                        width: 100%;
                        height: 100%;
                        // max-height: 800px;
                        object-fit: cover;
                    }
                }
            }
        }

        .right {
            flex: 1;
            gap: 10px;
            display: flex;
            padding-right: 150px;
            flex-direction: column;

            @include extralarge {
                padding-right: 50px;
            }

            @include large {
                padding-right: 0px;
            }

            .topRight {
                gap: 5px;
                display: flex;
                flex-direction: column;

                h2 {
                    color: $black;
                    font-size: 13px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    @include mobile{
                        font-size: 12px;
                    }
                }

                .priceWrapper {
                    gap: 5px;
                    display: flex;
                    align-items: center;

                    .price1 {
                        font-weight: 500;
                        color: $mainBlue;
                        font-size: 18px;
                    }
                    .price3 {
                        font-weight: 500;
                        color: $mainBlue;
                        font-size: 20px;
                    }
                }

                .discountWrapper{
                    gap: 8px;
                    display: flex;

                    .discountItem1{
                        gap: 3px;
                        display: flex;
                        color: $black;
                        align-items: center;
                        position: relative;
                        p{
                            font-weight: 500;
                            font-size: 14px;
                            font-family: AcuminLight;

                        }
                        span{
                            font-size: 15px;
                            color: $black;
                            font-family: AcuminLight;

                        }
                    }
                    .discountItem1:after {
                        position: absolute;
                        left: 0;
                        top: 43%;
                        height: 1px;
                        background: $black;
                        content: "";
                        width: 100%;
                        display: block;
                    }
                    .discountItem2{
                        gap: 5px;
                        display: flex;
                        color: crimson;
                        align-items: center;
                        p{
                            font-weight: 500;
                            font-size: 20px;
                        }
                        span{
                            font-size: 22px;
                        }
                    }
                }
            }

            hr {
                width: 70%;
                border: 0.4px dotted lightgray;
            }

            .filterColor {
                gap: 6px;
                display: flex;
                margin-top: 6px;

                span {
                    font-size: 12px;
                    font-family: AcuminLight;
                }
                .color {
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    font-family: AcuminBold;
                }
    
            }

            .filterSize {
                gap: 6px;
                display: flex;

                span {
                    font-size: 12px;
                    font-family: AcuminLight;
                }

                .selectedSize {
                    font-size: 13px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    font-family: AcuminBold;
                }
            }

            .similarColors {
                gap: 10px;
                display: flex;

                .imgWrapper {
                    width: 70px;
                    height: 50px;
                    cursor: pointer;
                    border-radius: 10px;
                    margin-bottom: 40px;

                    img {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        object-fit: cover;
                    }

                }

            }
            .similarColors2 {
                gap: 10px;
                display: flex;
                // margin-bottom: 0px;
            }


            .sizeWrapper {
                gap: 10px;
                display: flex;
                align-items: center;

                .size {
                    width: 35px;
                    height: 30px;
                    color: $black;
                    border: none;
                    cursor: pointer;
                    font-size: 14px;
                    padding: 5px 10px;
                    letter-spacing: 0.5px;
                    background-color: $mainColor;
                    outline: 0.4px solid $black;

                    &:focus {
                        color: $mainColor;
                        font-weight: 400;
                        background-color: $black;
                        outline: 0.4px solid $black;
                    }

                }

                .size1 {
                    width: 95px;
                    height: 25px;
                    color: $black;
                    border: none;
                    cursor: pointer;
                    font-size: 13px;
                    padding: 6px 10px 5px;
                    letter-spacing: 0.5px;
                    background-color: $mainColor;
                    outline: 0.4px solid $black;

                    &:focus {
                        color: $mainColor;
                        font-weight: 400;
                        background-color: $black;
                        outline: 0.4px solid $black;
                    }


                }

                .noSize {
                    width: 35px;
                    height: 30px;
                    border: none;
                    display: flex;
                    color: #B6B6B4;
                    cursor: pointer;
                    font-size: 14px;
                    position: relative;
                    align-items: center;
                    letter-spacing: 0.5px;
                    justify-content: center;
                    background-color: #DCDCDC;
                    outline: 0.4px solid #B6B6B4;

                }
            }


            .actions {
                gap: 18px;
                width: 70%;
                width: 100%;
                display: flex;
                margin-top: 10px;
                align-items: flex-start;
                flex-direction: column;
                justify-content: space-between;

                .selectQuantity {
                    font-size: 12px;
                    font-family: AcuminLight;
                }

                .quantity {
                    gap: 10px;
                    display: flex;
                    margin-top: -10px;
                    align-items: center;


                    span {
                        width: 35px;
                        height: 30px;
                        display: flex;
                        font-size: 14px;
                        align-items: center;
                        justify-content: center;
                        border: 0.4px solid gray;
                    }

                    button {
                        width: 35px;
                        height: 30px;
                        display: flex;
                        border: none;
                        padding: 5px 5px;
                        cursor: pointer;
                        align-items: center;
                        justify-content: center;
                        background-color: $dullBgrd;
                        border: 0.4px solid lightgray;

                        .qttyIcon {
                            font-size: 20px;
                            color: $subHeading;
                            background-color: $dullBgrd;
                        }
                    }
                }

                .addButtons {
                    gap: 15px;
                    width: 100%;
                    display: flex;

                    .cartBtn1 {
                        flex: 1.5;
                        gap: 10px;
                        width: 100%;
                        height: 38px;
                        border: none;
                        display: flex;
                        cursor: pointer;
                        color: $mainColor;
                        padding: 5px 10px;
                        align-items: center;
                        justify-content: center;
                        background-color: $black;
                        outline: 0.4px solid $black;

                        span {
                            font-size: 12px;
                            letter-spacing: 0.5px;
                            font-family: AcuminLight;
                        }

                        .cartIcon {
                            font-size: 12px;
                            margin-top: -5px;
                        }

                    }

                    .cartBtn2 {
                        flex: 1.5;
                        gap: 10px;
                        width: 100%;
                        height: 38px;
                        border: none;
                        display: flex;
                        color: white;
                        cursor: pointer;
                        padding: 5px 10px;
                        align-items: center;
                        justify-content: center;
                        background-color: #DCDCDC;
                        outline: 0.4px solid #B6B6B4;

                        span {
                            color: gray;
                            font-size: 13px;
                            letter-spacing: 0.5px;
                        }

                        .cartIcon {
                            display: none;
                        }
                    }

                    //like
                    .likedBtn {
                        flex: 0.8;
                        gap: 6px;
                        width: 100%;
                        height: 38px;
                        border: none;
                        display: flex;
                        cursor: pointer;
                        padding: 5px;
                        align-items: center;
                        justify-content: center;
                        background-color: $mainColor;
                        outline: 0.4px solid $mainBlue;

                        span {
                            color: $mainBlue;
                            font-size: 14px;
                            letter-spacing: 0.5px;
                            font-family: AcuminLight;
                        }

                        .likeIcon {
                            color: crimson;
                            font-size: 16px;
                            margin-top: -5px;
                        }

                    }

                    .likeBtn1 {
                        flex: 0.8;
                        gap: 6px;
                        width: 100%;
                        height: 38px;
                        border: none;
                        display: flex;
                        cursor: pointer;
                        padding: 5px;
                        align-items: center;
                        justify-content: center;
                        background-color: $black;
                        background-color: $mainColor;
                        outline: 0.4px solid $black;

                        span {
                            color: $black;
                            font-size: 14px;
                            letter-spacing: 0.5px;
                            font-family: AcuminLight;
                        }

                        .likeIcon {
                            color: crimson;
                            font-size: 16px;
                            margin-top: -5px;
                        }

                    }

                    .likeBtn2 {
                        flex: 0.8;
                        gap: 6px;
                        width: 100%;
                        height: 38px;
                        border: none;
                        display: flex;
                        color: white;
                        cursor: pointer;
                        padding: 5px;
                        align-items: center;
                        justify-content: center;
                        background-color: #DCDCDC;
                        outline: 0.4px solid #B6B6B4;

                        span {
                            color: gray;
                            font-size: 14px;
                            letter-spacing: 0.5px;
                            font-family: AcuminLight;
                        }

                        .likeIcon {
                            font-size: 16px;
                            margin-top: -5px;
                        }
                    }

                }
            }

            .divider {
                width: 100%;
                margin-top: 3px;
                margin-bottom: 3px;
                border: 0.7px solid gray;
            }

            .pdtDetails {
                gap: 10px;
                display: flex;
                flex-direction: column;

                h4 {
                    font-size: 13px;
                    color: $subHeading;
                    margin-bottom: 10px;
                    letter-spacing: 0.5px;
                    font-family: AcuminLight;

                }

                .desc {
                    font-size: 12.5px;
                    color: $black;
                    letter-spacing: 0.4px;
                    font-family: AcuminLight;
                }

                .codeWrapper {
                    gap: 10px;
                    display: flex;
                    align-items: center;

                    .codeTitle {
                        font-size: 12px;
                        color: $black;
                        letter-spacing: 0.4px;
                        font-family: AcuminLight;
                    }

                    .code {
                        font-size: 11px;
                        color: $black;
                        text-transform: uppercase;
                        letter-spacing: 0.5px;
                    }
                }

            }

            .policies {
                gap: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;

                .polociesTop {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h4 {
                        font-size: 13px;
                        color: $subHeading;
                        margin-bottom: 10px;
                        letter-spacing: 0.5px;
                        font-family: AcuminLight;

                    }

                    .dropIcon {
                        color: $mainBlue;
                        font-size: 16px;
                        cursor: pointer;
                        border-radius: 50%;
                        font-family: AcuminLight;
                        border: 0.3px solid $mainBlue;
                    }
                }

                .deliveryWrapper {
                    gap: 8px;
                    display: flex;
                    padding: 0px 5px 0px 5px;
                    flex-direction: column;

                    li {
                        gap: 3px;
                        font-size: 12px;
                        color: $black;
                        display: flex;
                        letter-spacing: 0.4px;
                        font-family: AcuminLight;

                        p {
                            font-size: 12px;
                            color: $black;
                            letter-spacing: 0.4px;
                            font-family: AcuminLight;
                        }

                        .returnLink {
                            font-size: 12px;
                            color: $mainBlue;
                            font-family: AcuminBold;
                        }
                    }
                }


            }


        }

    }
}