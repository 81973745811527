@import "../../global.scss";

.login {
    width: 100%;
    display: flex;
    margin-top: 70px;
    padding: 70px 100px 50px;
    align-items: center;
    justify-content: center;

    @include large {
        padding: 20px 50px 50px;
    }
    @include mobile {
        padding: 20px 50px 50px;
    }

    .link {
        text-decoration: none;
        color: inherit;
        width: 100%;
    }

    .loginWrapper {
        gap: 20px;
        width: 100%;
        display: flex;
        padding: 20px 30px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include mobile {
            padding: 20px 10px;
        }

        .top {
            gap: 20px;
            display: flex;

            h3 {
                color: $black;
                font-size: 20px;
                margin-top: 20px;
                margin-bottom: 6px;
                letter-spacing: 0.5px;
                // font-family: AcuminBold;
                @include phone{
                    font-size: 25px;
                }
            }
        }


        .loginFormWrapper {
            gap: 10px;
            width: 450px;
            display: flex;
            padding: 30px 30px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            @include phone{
                width: 400px;
                padding: 30px 20px;
            }

            .inputItem {
                gap: 5px;
                width: 100%;
                display: flex;
                flex-direction: column;

                span {
                    font-size: 14px;
                    font-family: AcuminLight;
                }

                .emailInput {
                    width: 100%;
                    height: 45px;
                    font-size: 15px;
                    padding: 0px 15px;
                    border: 0.7px solid lightgray;
                }

                .emailInput::placeholder {
                    font-size: 14px;
                    font-family: AcuminLight;
                }

                .emailInput:focus {
                    outline: none;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-text-fill-color: $black;
                    transition: background-color 5000s ease-in-out 0s;
                }

                .password {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding: 0px 5px;
                    justify-content: space-between;
                    border: 0.7px solid lightgray;
                    background-color: $mainColor;

                    .passwordInput {
                        flex: 2.5;
                        height: 100%;
                        font-size: 15px;
                        padding: 0px 15px;
                        border: none;
                    }

                    .passwordInput::placeholder {
                        font-size: 14px;
                        font-family: AcuminLight;
                    }

                    .passwordInput:focus {
                        outline: none;
                    }

                    label {
                        flex: 0.3;
                        height: 40px;
                        color: gray;
                        display: flex;
                        text-align: center;
                        padding: 0px 20px;
                        cursor: pointer;
                        align-items: center;
                        background-color: $hr;
                        // align-self: right;
                    }

                }

            }

            .forgotPassword {
                width: 100%;
                display: flex;
                margin-top: 5px;
                margin-bottom: 5px;
                align-items: flex-start;
                justify-content: flex-start;

                span {
                    font-size: 13px;
                    color: $mainBlue;
                    text-decoration: underline;
                    font-family: AcuminLight;
                }
            }

            .loginBtn {
                width: 100%;
                height: 45px;
                font-size: 14px;
                margin-top: 0px;
                padding: 0px 15px;
                cursor: pointer;
                color: $mainColor;
                letter-spacing: 0.6px;
                border: 1px solid $black;
                background-color: $black;
            }

            .errorWrapper{
                gap: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                .error{
                    color: red;
                    font-size: 11px;
                }
                .reload{
                    font-size: 10px;
                    color: blue;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            button:hover,
            button:focus {
                border: 1px solid $black;
                color: $mainColor;
                background-color: $black;
            }

            .error {
                color: crimson;
                font-size: 14px;
            }

            .loginFooter {
                gap: 10px;
                width: 100%;
                display: flex;
                margin-top: 5px;
                align-items: center;
                flex-direction: column;

                span {
                    font-size: 10px;
                    font-family: AcuminLight;
                }
                p {
                    font-size: 12px;
                    font-family: AcuminLight;
                }
                .loginRegisterLink{
                    font-size: 12px;
                    color: $mainBlue;
                    text-decoration: none;
                    font-family: AcuminBold;
                }
                .loginLink{
                    font-size: 10px;
                    color: $mainBlue;
                    text-decoration: none;
                    font-family: AcuminBold;
                }
            }

        }





    }

}









// .success {
//     width: 100vw;
//     height: 100vh;
//     display: flex;
//     padding: 0px 150px;
//     align-items: center;
//     justify-content: center;
//     background-color: $pageBackground;

//     .successModal {
//         // width: 450px;
//         gap: 20px;
//         display: flex;
//         padding: 50px;
//         border-radius: 10px;
//         // align-items: center;
//         background-color: $mainColor;
//         // justify-content: center;
//         flex-direction: column;
//         box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

//         .modalIcon {
//             width: 60px;
//             height: 60px;
//             border: none;
//             color: $mainBlue;
//         }

//         h1 {
//             font-size: 30px;
//             color: $black;
//         }

//         p {
//             font-weight: 300;
//             font-size: 20px;
//         }

//         .modalBtn {
//             width: 100%;
//             border: none;
//             color: $mainColor;
//             padding: 10px;
//             font-size: 16px;
//             border-radius: 5px;
//             background-color: $mainBlue;
//         }
//     }
// }


