@import '../../global.scss';


.mainSale{
    width: 100%;
    display: flex;
    margin-top: 100px;
    margin-top: 140px;
    flex-direction: column;

    @include large {
        margin-top: 85px;
    }

    .brandWrapper {
        display: flex;
        padding: 10px 30px;
        flex-direction: column;

        @include large {
            padding: 10px 10px;
        }

        .top {
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;

            .routes {
                display: flex;
                color: gray;
                font-size: 12px;
                align-items: center;
                font-family: AcuminLight;

                .routeIcon {
                    display: flex;
                    font-size: 14px;
                    align-items: center;
                    margin-top: -2px;
                }
            }

            .heading {
                gap: 10px;
                width: 100%;
                height: 160px;
                height: 140px;
                display: flex;
                color: $black;
                padding: 10px 25px;
                flex-direction: column;
                border: 0.5px solid lightgray;
                align-items: center;
                justify-content: center;
                // background-size: cover;
                // background-image: url("../../assets/brands/saleBack.png");
                // background-color: $lime2;
                background-color: $Sale;

                @include tablet{
                    padding: 20px 25px 10px;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                h2 {
                    font-size: 20px;
                    color: $mainRed;
                    color: $mainColor;
                    font-family: AcuminBold;
                    text-transform: uppercase;
                    // text-shadow: 0.5px 0.5px 1px $black;

                    @include tablet{
                        margin-top: 10px;
                        font-size: 20px;
                    }
                }

            }

            .filtersWrapper {
                width: 100%;
                display: flex;
                margin-top: 10px;
                height: 40px;
                align-items: center;
                justify-content: space-between;
                border-top: 0.4px solid lightgray;
                border-bottom: 0.4px solid lightgray;

                .left {
                    gap: 20px;
                    display: flex;
                    height: 100%;
                    align-items: center;

                    span {
                        font-size: 13px;
                        font-family: AcuminLight;
                    }

                    .selectFilters {
                        gap: 15px;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        position: relative;

                        // flex-direction: column;

                        .selectFilterItem {
                            gap: 5px;
                            height: 100%;
                            display: flex;
                            align-items: center;

                            .itemName1 {
                                font-size: 12px;
                                color: $mainRed;
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                            }

                            .itemName2 {
                                color: $black;
                                font-size: 12px;
                                letter-spacing: 0.5px;
                                text-transform: uppercase;
                            }

                            .dropIcon1 {
                                font-size: 20px;
                                margin-top: -3px;
                                color: $mainRed;
                            }

                            .dropIcon2 {
                                font-size: 20px;
                                margin-top: -3px;
                                color: $black;
                            }
                        }

                        .optionsWrapper {
                            top: 100%;
                            gap: 20px;
                            width: 210px;
                            display: flex;
                            position: absolute;
                            padding: 12px 20px;
                            background-color: $mainColor;
                            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                            .options {

                                .filterItem {
                                    display: block;
                                    position: relative;
                                    padding-left: 22px;
                                    margin-bottom: 12px;
                                    cursor: pointer;
                                    -webkit-user-select: none;
                                    -moz-user-select: none;
                                    -ms-user-select: none;
                                    user-select: none;
                                    align-items: center;
                                    justify-content: center;

                                    input {
                                        opacity: 0;
                                        cursor: pointer;
                                        position: absolute;
                                    }

                                    p {
                                        top: 3;
                                        font-size: 12.5px;
                                        font-family: AcuminLight;
                                    }

                                    .checkmark {
                                        top: 0;
                                        left: 0;
                                        height: 14px;
                                        width: 14px;
                                        border-radius: 50%;
                                        position: absolute;
                                        background-color: #eee;

                                        margin-top: -1px;

                                    }

                                }

                                .all {
                                    background: linear-gradient($mainBlue, crimson);
                                }

                                .filterItem:hover input~.checkmark {
                                    background-color: #ccc;
                                }

                                .filterItem input:checked~.checkmark {
                                    background-color: $mainBlue;
                                }

                                .checkmark:after {
                                    content: "";
                                    display: none;
                                    position: absolute;
                                }

                                .filterItem input:checked~.checkmark:after {
                                    display: block;
                                }

                                .filterItem .checkmark:after {
                                    top: 3.5px;
                                    left: 3.4px;
                                    width: 7px;
                                    height: 7px;
                                    border-radius: 50%;
                                    background: white;
                                }

                                .line {
                                    margin-top: 3rem;
                                    border-color: #f7f7f7;
                                }

                                .read-more-link {
                                    color: $mainBlue;
                                    cursor: pointer;
                                    font-size: 12.5px;
                                    font-weight: 300;
                                    display: flex;
                                    margin-top: 10px;
                                    margin-bottom: 5px;
                                    align-items: center;
                                    letter-spacing: 0.5px;
                                    letter-spacing: 0.4px;
                                    text-decoration: underline;

                                    p {
                                        color: $mainBlue;
                                        font-weight: 300;
                                        margin-bottom: 2px;

                                    }
                                }

                            }
                        }
                    }
                }

                .right {
                    gap: 20px;
                    display: flex;
                    height: 100%;
                    padding: 3px 0px;
                    align-items: center;

                    span {
                        font-size: 14px;
                        font-family: AcuminLight;
                    }

                    .filter {

                        &__option {
                            padding: 10px;
                            font-size: 14px;
                            font-family: AcuminLight;
                            background-color: white;

                            &--is-focused {
                                background-color: $limelight;
                            }

                            &--is-selected {
                                color: $deepBlack;
                            }
                        }

                    }


                }
            }
        }

    }
}