@import "../../../global.scss";

// ------------ RESET PASSWORD -----------
.reset-login {
    gap: 20px;
    width: 100%;
    display: flex;
    margin-top: 90px;
    padding: 130px 100px 90px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .link {
        text-decoration: none;
        color: inherit;
    }

    .form {
        width: 450px;
        padding: 40px 40px 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        background-color: $mainColor;
        border: 0.5px solid $lighterGray;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        @include tablet {
            padding: 20px;
            width: 400px;
        }

        @include phone {
            padding: 20px 10px;
        }

        h4 {
            font-size: 14px;
            margin-bottom: 20px;
            letter-spacing: 0.5px;
            color: $black;
            // font-family: AcuminBold;
        }

        h3 {
            font-size: 14px;
            margin-bottom: 20px;
            letter-spacing: 0.5px;
            color: $black;
            // font-family: AcuminBold;
        }

        form {
            gap: 18px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        input {
            width: 100%;
            height: 50px;
            font-size: 15px;
            padding: 0px 15px;
            border: 0.7px solid lightgray;
        }

        input::placeholder {
            font-size: 14px;
            font-family: AcuminLight;
            
        }

        input:focus {
            outline: none;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-text-fill-color: $black;
            transition: background-color 5000s ease-in-out 0s;
        }

        .password {
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0.7px solid lightgray;

            input {
                flex: 2.5;
                height: 100%;
                font-size: 15px;
                padding: 0px 15px;

                border: none;
            }

            input::placeholder {
                font-size: 14px;
                font-family: AcuminLight;
            }

            input:focus {
                outline: none;
            }

            label {
                flex: 1;
                color: gray;
                text-align: right;
                padding: 0px 20px;
                cursor: pointer;
            }

        }

        button {
            width: 100%;
            height: 45px;
            border: none;
            font-size: 18px;
            cursor: pointer;
            padding: 0px 15px;
            border: none;
            color: $mainColor;
            background-color: $black;

            p {
                font-size: 13px;
                letter-spacing: 0.5px;
                font-family: AcuminLight;
            }

            @include phone {
                font-size: 16px;
            }

        }

        button:hover,
        button:focus {
            border: 1px solid $black;
            color: $black;
            background-color: #fff;
        }

        .error {
            color: crimson;
            font-size: 14px;
        }

    }

    .bottom {
        gap: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        justify-content: center;
        flex-direction: column;

        .bottomItem {
            gap: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-weight: 300;
                font-size: 12px;
                margin-top: 5px;
                color: $black;
                text-align: center;
                letter-spacing: 0.5px;
            }

            p {
                font-weight: 400;
                font-size: 12px;
                margin-top: 5px;
                cursor: pointer;
                text-align: center;
                letter-spacing: 0.5px;
                color: $mainBlue;
            }

        }

        .password {
            font-weight: 300;
            font-size: 13px;
            cursor: pointer;
            text-align: center;
            letter-spacing: 0.7px;
            color: crimson;
        }
    }

}


.success {
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 0px 150px;
    align-items: center;
    justify-content: center;
    background-color: $pageBackground;

    .successModal {
        // width: 450px;
        gap: 20px;
        display: flex;
        padding: 50px;
        border-radius: 10px;
        // align-items: center;
        background-color: $mainColor;
        // justify-content: center;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .modalIcon {
            width: 60px;
            height: 60px;
            border: none;
            color: $mainBlue;
        }

        h1 {
            font-size: 30px;
            color: $black;
        }

        p {
            font-weight: 300;
            font-size: 20px;
        }

        .modalBtn {
            width: 100%;
            border: none;
            color: $mainColor;
            padding: 10px;
            font-size: 16px;
            border-radius: 5px;
            background-color: $mainBlue;
        }
    }
}



// ------------ PASSWORD SUCCESS -----------
.Modal {
    top: 200px;
    left: auto;
    right: auto;
    gap: 30px;
    width: 40%;
    bottom: auto;
    padding: 30px;
    z-index: 1000;
    display: flex;
    align-items: center;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    border-radius: .5em;
    background-color: $mainColor;

    @include large {
        // top: 30px;
        width: 55%;
    }

    @include tablet {
        // top: 30px;
        width: 55%;
    }

    @include mobile {
        // top: 30px;
        width: 70%;
    }

    .header {
        h2 {
            font-size: 25px;
            color: $black;
        }
    }

    .content {
        p {
            color: $black;
            font-weight: 300;
            font-size: 18px;
            text-align: center;
            font-family: AcuminLight;
        }
    }

    .link {
        width: 100%;
        color: inherit;
        text-decoration: none;

        .actions {
            width: 100%;
            color: $mainColor;
            border: none;
            padding: 10px;
            display: flex;
            font-size: 15px;
            align-items: center;
            border-radius: 3px;
            justify-content: center;
            background-color: $mainBlue;
            font-family: 'Assistant', sans-serif;
        }
    }

}

.Overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(128, 128, 128, .9),

}