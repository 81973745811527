@import '../../../global.scss';


// -----------KIDS NAV-----------
.kidsNav {
    top: 100px;
    gap: 10px;
    z-index: 3;
    width: 100%;
    height: 40px;
    display: flex;
    position: fixed;
    padding: 25px 0px;
    align-items: center;
    // z-index: 2;
    justify-content: space-between;
    padding: 0px 30px;
    background-color: $mainColor;
    border-bottom: 0.4px solid lightgray;


    @include large {
        display: none;
    }

    .kidsNavLink {
        width: 100%;
        height: 100%;
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    .kidsNavLeft {
        flex: 1.5;
        height: 100%;
        width: 100%;
        display: flex;
        margin-right: 0px;
        align-items: center;
        justify-content: space-between;

        @include extralarge {
            margin-right: 0px;
            flex: 1.8;
        }

        .navbar-links {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;


            .navList {
                width: 100%;
                height: 100%;
                display: flex;
                list-style: none;
                align-items: center;
                justify-content: space-between;
                text-transform: capitalize;

                .navItem {
                    width: 100%;
                    height: 100%;
                    font-size: 12.5px;
                    cursor: pointer;
                    color: $deepBlack;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    letter-spacing: 0.4px;
                    font-family: AcuminExtraLight;
                    border-bottom: 2px solid transparent;
                    transition: border-bottom 1s;

                    span {
                        margin-top: 3px;
                        letter-spacing: 1.1px;
                        // font-family: AcuminExtraLight;
                    }

                    p {
                        margin-top: 3px;
                        letter-spacing: 1.1px;
                        // font-family: AcuminExtraLight;
                    }

                    &:hover {
                        border-bottom: 2px solid $deepBlack;
                    }
                }


                @include large {
                    display: none;
                }
            }

        }

    }

    .kidsNavRight {
        flex: 1;
        width: 100%;
        height: 100%;
        height: 36px;
        display: flex;
        margin-bottom: 2px;
        align-items: center;
        background-color: white;
        justify-content: space-between;
        border: 0.5px solid lightgray;

        .searchWrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            background-color: lightgray;

            .searchInput {
                flex: 1;
                width: 100%;
                height: 100%;
                padding: 10px;
                font-size: 14.5px;
                color: #282828;
                border: none;
            }

            input::placeholder {
                font-size: 14px;
                font-family: AcuminLight;
            }

            input:focus {
                outline: none;
            }


            .navSearchIcon {
                flex: 0.01;
                display: flex;
                width: 100%;
                height: 100%;
                padding: 10px;
                font-size: 18px;
                color: $gray;
                align-items: center;
                justify-content: center;
                border: none;
                border-left: 0.5px solid lightgray;
            }

            .navOverlay {
                left: 0;
                right: 0;
                top: 40px;
                bottom: 0;
                position: absolute;
                background-color: #282828;
            }


            .navSearchOptions {
                top: 40px;
                gap: 5px;
                z-index: 1;
                width: 490px;
                // width: 100%;
                right: 30px;
                color: $subHeading;
                display: flex;
                backdrop-filter: blur(4px);
                list-style: none;
                padding: 10px 5px;
                position: absolute;
                flex-direction: column;
                background-color: $mainColor;
                box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                .optionsLink {
                    color: inherit;
                    text-decoration: none;
                }

                .searchItemWrapper {
                    gap: 10px;
                    display: flex;
                    align-items: center;

                    .searchImg {
                        width: 60px;
                        height: 65px;
                    }

                    .searchText {
                        gap: 5px;
                        display: flex;
                        flex-direction: column;

                        span {
                            font-size: 12.5px;
                            text-transform: uppercase;
                        }

                        p {
                            font-size: 12px;
                            color: gray;
                            font-family: AcuminLight;
                        }
                    }

                }



            }
        }

    }

}


// -----------KIDS NAV LIST-----------
.kids-listItem {
    width: 100%;
    height: 100%;
    font-size: 13px;
    cursor: pointer;
    color: $deepBlack;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.4px;
    font-family: AcuminExtraLight;
    border-bottom: 2px solid transparent;
    transition: border-bottom 1s;

    span {
        margin-top: 3px;
        letter-spacing: 1.1px;
        font-family: AcuminExtraLight;
    }

    p {
        margin-top: 3px;
        letter-spacing: 1.1px;
        font-family: AcuminExtraLight;
    }

    &:hover {
        border-bottom: 2px solid $deepBlack;
    }


}

.kids-listItem2 {
    width: 100%;
    height: 100%;
    font-size: 13px;
    cursor: pointer;
    color: $black;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    justify-content: center;
    font-family: AcuminExtraLight;
    border-bottom: 2px solid $black;
    transition: border-bottom 1s;

    span {
        margin-top: 3px;
        letter-spacing: 1.1px;
        font-family: AcuminBold;
    }

    p {
        margin-top: 3px;
        letter-spacing: 1.1px;
        font-family: AcuminBold;
    }

}