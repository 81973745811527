@import '../../global.scss';


.adminMenuItem{
    gap: 12px;
    width: 100%;
    display: flex;
    cursor: pointer;
    color: $subHeading;
    padding: 13px 30px;
    align-items: center;
    margin-bottom: 5px;
    justify-content: center;
    border: 0.4px solid lightgray;

    @include phone{
        font-size: 14.5px;
    }

    .menuItemLabel{
        flex: 1;
        font-size: 14.5px;
        margin-top: 3px;
        font-family: AcuminLight;
    }
    .menuItemIcon{
        flex: 0.3;
        display: flex;
        font-size: 20px;
        align-items: flex-end;

    }
}

.activated{
    background-color: $black;

    .menuItemLabel{
        color: $mainColor;
    }
    .menuItemIcon{
        color: $mainColor;

    }
}