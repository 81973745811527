@import '../../global.scss';

//MAIN NAV
.nav-listItem{
    width: 100%;
    height: 100%;
    // padding: 7px;
    font-size: 15px;
    cursor: pointer;
    color: $deepBlack;
    letter-spacing: 0.4px;
    font-family: AcuminExtraLight;
    border-bottom: 2px solid transparent;
    transition: border-bottom 1s;
   
    &:hover{
        border-bottom: 1px solid $deepBlack;
    }
    &.active{
        font-family: AcuminBold;
        border-bottom: 1px solid $deepBlack;
    }
}

