@import '../../global.scss'; // Edit Member

.editMember {
    display: flex;
    width: 100%;
    overflow-y: auto;
    // padding: 0px 100px;
    border: 0.2px solid lightgrey;

    @include extralarge {
        padding: 0px 30px;
    }

    @include large {
        padding: 50px 100px;
    }

    @include tablet {
        padding: 50px 80px;
    }

    @include mobile {
        padding: 50px 20px;
    }

    .wrapper {
        padding: 20px;
        display: flex;
        width: 100%;

        gap: 20px;

        @include large {
            width: 100%;
            padding: 0px;
            flex-direction: column;
        }

        .singleLeft {
            flex: 1;
            padding: 30px;
            border-radius: 3px;
            position: relative;
            background-color: $mainColor;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

            @include large {
                width: 100%;

            }

            .title {
                font-size: 16px;
                font-weight: 400;
                color: $moneyGreen;
                font-family: AcuminLight;
            }

            .imgWrapper {
                height: 110px;
                width: 90px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    object-fit: cover;

                }

                @include tablet {
                    height: 100px;
                    width: 100px;
                }
            }

            .item {
                gap: 40px;
                display: flex;
                margin-top: 22px;

                @include tablet {
                    gap: 20px;
                }

                .itemDetails {

                    @include tablet {
                        margin-left: 10px;
                    }

                    .detailsTitle {
                        font-size: 14px;
                        letter-spacing: 0.5px;
                        margin-bottom: 13px;
                        color: $mainBlue;
                        font-family: AcuminMedium;
                    }


                    .detailsItem {
                        gap: 0px;
                        display: flex;
                        font-size: 14px;
                        margin-bottom: 8px;
                        flex-direction: column;

                        .itemKey {
                            color: $gray;
                            font-size: 14px;
                            font-family: AcuminMedium;
                        }

                        .itemValue {
                            font-size: 14px;
                            color: $mainBlue;
                            font-family: AcuminLight;
                        }

                        .itemValue3 {
                            font-size: 14px;
                            color: $Sale;
                            font-family: AcuminMedium;
                        }

                    }

                    .text {
                        gap: 3px;
                        display: flex;
                    }


                    .itemDetailsWrapper {
                        gap: 10px;
                        width: 100%;
                        display: flex;
                        margin-top: 10px;
                        align-items: center;


                        .detailsItem {
                            gap: 0px;
                            flex: 1;
                            display: flex;
                            font-size: 14px;
                            margin-bottom: 8px;
                            flex-direction: column;

                            .itemKey {
                                color: $gray;
                                font-size: 14px;
                                font-family: AcuminMedium;
                            }

                            .itemValue {
                                font-size: 14px;
                                color: $mainBlue;
                                font-family: AcuminLight;
                            }

                            .itemValue2 {
                                font-size: 14px;
                                color: $moneyGreen;
                                font-family: AcuminMedium;
                            }

                            .itemValue4 {
                                color: $mainBlue;
                                font-size: 12px;
                                font-family: AcuminMedium;
                            }

                        }
                    }

                    .text {
                        gap: 3px;
                        display: flex;
                    }

                    .detailsItem2 {
                        margin-bottom: 10px;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        gap: 20;

                        @include tablet {
                            gap: 6px;
                        }

                        .itemKey {
                            font-weight: bold;
                            color: gray;
                            margin-right: 10px;

                            @include tablet {
                                margin-right: 0px;
                            }
                        }

                        .itemValue {
                            font-weight: 300;
                        }
                    }
                }

            }
        }


        .singleRight {
            flex: 1;
            padding: 20px 10px;
            border-radius: 3px;
            background-color: $mainColor;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

            @include mobile {
                padding: 40px 20px;
            }

            .title {
                font-size: 16px;
                font-family: AcuminLight;
                color: $moneyGreen;
            }

            form {
                gap: 5px;
                width: 100%;
                // background-color: pink;

                display: flex;
                padding: 10px 0px;
                flex-direction: column;

                .inputWrapper {
                    gap: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;


                    input {
                        flex: 1;
                        // width: 350px;
                        padding: 8px 10px;
                        height: 40px;
                        border: 0.5px solid lightgray;
                        border-radius: 3px;

                        @include large {
                            width: 100%;
                        }

                    }

                    input::placeholder {
                        color: gray;
                        font-size: 13px;
                        margin-left: 10px;
                    }

                    input:focus {
                        outline: none;
                    }
                }

                .toggleWrapper{
                    gap: 5px;
                    width: 100%;
                    display: flex;
                    margin-top: 10px;
                    align-items: center;

                    .toggle {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        flex: 1;

                        .toggleText{
                            font-size: 14px;
                            color: $black;

                        }
                
                        .switch {
                          position: relative;
                          display: inline-block;
                        //   width: 50px;
                        //   height: 24px;
                            width: 40px;
                            height: 20px;
                        }
                
                        /* Hide default HTML checkbox */
                        .switch input {
                          opacity: 0;
                          width: 0;
                          height: 0;
                        }
                
                        .slider {
                          position: absolute;
                          cursor: pointer;
                          top: 0;
                          left: 0;
                          right: 0;
                          bottom: 0;
                          background-color: #ccc;
                          -webkit-transition: 0.4s;
                          transition: 0.4s;
                          border-radius: 24px;
                        }
                
                        .slider:before {
                          position: absolute;
                          content: "";
                        //   height: 16px;
                        //   width: 16px;
                        height: 14px;
                          width: 14px;
                          left: 4px;
                          bottom: 3px;
                          background-color: white;
                          -webkit-transition: 0.4s;
                          transition: 0.4s;
                          border-radius: 50%;
                        }
                
                        input:checked + .slider {
                          background-color: #2196f3;
                        }
                
                        input:focus + .slider {
                          box-shadow: 0 0 1px $mainBlue;
                        }
                
                        input:checked + .slider:before {
                          -webkit-transform: translateX(20px);
                          -ms-transform: translateX(20px);
                          transform: translateX(20px);
                        }
                      }
                }


                .formButton {
                    width: 250px;
                    padding: 10px;
                    border: none;
                    font-size: 14px;
                    color: white;
                    cursor: pointer;
                    margin-top: 20px;
                    border-radius: 5px;
                    align-self: center;
                    background-color: $moneyGreen;

                    @include large {
                        width: 100%;
                    }
                }

            }

        }
    }

}






