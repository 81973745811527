@import '../../global.scss';

// // ----------- Delivery Type -----------
.deliveryType {
    gap: 10px;
    display: flex;
    flex-direction: column;

    .item1 {
        display: flex;
        align-items: center;

        span {
            font-size: 13px;
            margin-top: 5px;
            color: $moneyGreen;
            letter-spacing: 0.5px;
            font-family: AcuminLight;
        }

        .typeIcon {
            font-size: 18px;
            color: $moneyGreen;
        }
    }

    .item2 {
        gap: 5px;
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
            color: $subHeading;
            font-family: AcuminLight;
        }

        h4 {
            font-size: 14px;
            color: $black;
            font-family: AcuminMedium;
        }
    }

    .addressInfo{
        display: flex;
        margin-top: 10px;
        flex-direction: column;

        h3{
            font-size: 17px;
            color: $subHeading;
            font-family: AcuminMedium;
        }

        .addresses{
            gap: 40px;
            width: 100%;
            display: flex;
            margin-top: 10px;

            .left{
                flex: 1;
                gap: 10px;
                display: flex;
                flex-direction: column;

                p{
                    font-size: 13px;
                    margin-top: 10px;
                    color: $gray2;
                    font-family: AcuminLight;
                }
                .addressBtn1{
                    gap: 8px;
                    display: flex;
                    padding: 10px;
                    border: none;
                    cursor: pointer;
                    flex-direction: column;
                    background-color: $mainColor;
                    border: 0.5px solid lightgrey;

                    &:focus {
                        border: solid 1.5px $lightBlue;
                        span {
                            color: $black2;
                            font-family: AcuminMedium;
                        }
                    }

                    span {
                        font-size: 14px;
                        color: $subHeading;
                        font-family: AcuminLight;
                    }
                }
    
            }
            .right{
                flex: 1;
                gap: 5px;
                display: flex;
                flex-direction: column;

                p{
                    font-size: 13px;
                    margin-top: 10px;
                    color: $gray2;
                    font-family: AcuminLight;
                }

                .add{
                    gap: 5px;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    border: none;
                    background-color: $offwhite;
                    p{
                        font-size: 13px;
                        margin-top: 10px;
                        color: $lightBlue;
                        letter-spacing: 0.5px;
                        font-family: AcuminLight;
                    }
                    .addIcon{
                        font-size: 16px;
                        margin-top: 5px;
                        color: $lightBlue;
                    }
                }
                .addressBtn1{
                    gap: 8px;
                    display: flex;
                    padding: 10px;
                    border: none;
                    cursor: pointer;
                    flex-direction: column;
                    background-color: $mainColor;
                    border: 0.5px solid lightgrey;

                    // &:hover{
                    //     border: solid 1.5px $lightBlue;
                    //     span {
                    //         color: $black2;
                    //         font-family: AcuminMedium;
                    //     }
                    // }
                    &:focus {
                        border: solid 1.5px $lightBlue;
                        span {
                            color: $black2;
                            font-family: AcuminMedium;
                        }
                    }

                    span {
                        font-size: 14px;
                        color: $subHeading;
                        font-family: AcuminLight;
                    }
                }
            }
        }
        
    }

    .paymentWrapper{
        gap: 15px;
        width: 100%;
        display: flex;
        margin-top: 50px;
        align-items: center;
        flex-direction: column;

        span {
            font-size: 11px;
            letter-spacing: 0.3px;
            font-family: AcuminLight;
        }
        .termsLink{
            font-size: 10px;
            color: $mainBlue;
            text-decoration: none;
            font-family: AcuminBold;
        }

        .payBtn1 {
            gap: 10px;
            width: 100%;
            height: 38px;
            border: none;
            display: flex;
            cursor: pointer;
            color: $mainColor;
            padding: 5px 10px;
            align-items: center;
            justify-content: center;
            background-color: $mainBlue;
            outline: 0.4px solid $mainBlue;

            span {
                font-size: 12px;
                letter-spacing: 0.5px;
                font-family: AcuminLight;
            }

            .cartIcon {
                font-size: 12px;
                margin-top: -5px;
            }

        }

        .payBtn2 {
            gap: 10px;
            width: 100%;
            height: 38px;
            border: none;
            display: flex;
            cursor: pointer;
            padding: 5px 10px;
            align-items: center;
            justify-content: center;
            background-color: #DCDCDC;
            outline: 0.4px solid #B6B6B4;

            span {
                color: gray;
                font-size: 13px;
                letter-spacing: 0.5px;
            }

            .cartIcon {
                font-size: 16px;
                margin-top: -5px;
            }
        }
    }
}



// // ----------- Delivery Info -----------
.shipping-typeItem{
    gap: 10px;
    flex: 1;
    color: gray;
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $mainColor;
    border: 0.5px solid lightgrey;

    .infoTitle {
        font-size: 16px;
        color: $subHeading;
        font-family: AcuminLight;
    }

    .infoDesc {
        font-size: 13px;
        color: gray;
        font-family: AcuminLight;
    }
    @include mobile{
        font-size: 13px;
    }
    @include phone{
        font-size: 14.5px;
    }
   
    &.active{
        color: $mainBlue;
        border: solid 1.5px $lightBlue;

        .infoTitle {
            font-size: 16px;
            color: $subHeading;
            font-family: AcuminBold;
        }
    }
}


// // ----------- New Address Modal -----------
.Modal {
    top: 120px;
    left: auto;
    right: auto;
    bottom: auto;
    position: absolute;
    background-color: $mainColor;
    padding: 10px 5px;
    z-index: 1000;
    width: 30%;
    border-radius: .5em;
    @include tablet{
        top: 30px;
        width: 65%;
    }
    @include mobile{
        top: 30px;
        width: 85%;
    }

    .closeBtn{
        color: red;
        display: flex;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 6px 13px;
        align-items: center;
        border: 0.5px solid crimson;
        p{
            font-size: 16px;
            font-family: AcuminLight;
        }
    }

}
.Overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(128,128,128, .9),
    
}
