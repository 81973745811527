@import '../../../global.scss';


.dropdown-Overlay{
    top: 140px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0,0,0, .5),
}
.dropdownWrapper {
    // margin-top: 0px;
    top: 40px;
    gap: 5px;
    left: 30px;
    width: 780px;
    height: 420px;
    color: $black;
    display: flex;
    float: center;
    padding: 10px 25px;
    position: absolute;
    justify-content: space-between;
    background-color: $pageBackground;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px;

    .dropdown-menu {
        display: flex;
        list-style: none;
        flex-direction: column;
        
        .dropdown-link {
            display: block;
            height: 100%;
            width: 100%;
            text-decoration: none;
            color: inherit;
            padding: 12px;

            span{
                font-size: 14px;
                font-family: AcuminLight;
            }
            &:hover{
                text-decoration: underline;
            }
        }
        .dropdown-link2 {
            display: block;
            height: 100%;
            width: 100%;
            text-decoration: none;
            color: inherit;
            padding: 13px;

            span{
                font-size: 14px;
                font-family: AcuminMedium;
            }

            &:hover{
                text-decoration: underline;
            }
        }
        .dropdown-link3 {
            display: block;
            height: 100%;
            width: 100%;
            text-decoration: none;
            color: inherit;
            padding: 13px;

            span{
                font-size: 14px;
                color: $Sale;
                font-family: AcuminMedium;
            }

            &:hover{
                text-decoration: underline;
            }
        }
    
    }
    .dropDownImg {
        width: 350px;
        height: 280px;
    }

}




